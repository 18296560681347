
.not-found-list {
	.list-reset;
	padding: 2.5rem 0 0;

    .not-found-list__item {
        & + .not-found-list__item {
            margin: 0.5rem 0 0;
        }
    }

    .not-found-list__link{
        display: flex;
        align-items: center;
        color: @color-primary;
        background: #fff;
        border-radius: @border-radius;
        border: 2px solid transparent;
        padding: 1rem 1.5rem;
        transition: .15s;

        @media (min-width: @mq-l) {
            padding: 1.375rem 1.5rem;
        }

        .text {
            .weight-bold;
        }

        .arrow {
            margin: 0 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        &:hover,
        &:focus {
            outline: 0;
            border-color: @color-primary;
        }
    }
}


/*-----------------------------------------------------------------------------------------
	/CMS Blocks
-----------------------------------------------------------------------------------------*/
.cms-block {
	margin-bottom: 3em;

	&:last-child {
		margin-bottom: 0;
	}
	
	.wysiwyg {
		h1,
		h2,
		h3 {
			.heading--block;
			margin-bottom: 1em;
			* + & {
				margin-top: 1em;
			}
		}
		figure + * {
			margin-top: 1em;
		}
	}
}

.cms-block--inline-image,
.cms-block--inline-image__img {
	position: relative;
}

/*-----------------------------------------------------------------------------------------
	/Video embed
-----------------------------------------------------------------------------------------*/
.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
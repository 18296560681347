/*-----------------------------------------------------------------------------------------
	/Header
-----------------------------------------------------------------------------------------*/
.store-header {
	padding: 0 0 2rem;
	@media (min-width: @mq-xl) {
		padding: 4rem 0;
	}
	@media (max-width: (@mq-xl - 0.0625em)) {
		padding: 0;
		.desktop-only {
			display: none;
		}
	}
}
.store-header__layout {
	.grid-row {
		align-items: center;
		+ .grid-row {
			@media (min-width: @mq-xl) {
				margin-top: 1rem;
			}
		}
	}
}
.store-header__nav {
	@media (min-width: 112.5em) { //1800px
		margin-left: -8.33333333%;
	}
}
.store-header__nav-back {
	min-width: 100%;
	display: flex;
	align-items: center;
	background: @color-primary;
	color: #fff;
	font-size: 0.75rem;
	line-height: 1.3;
	text-transform: uppercase;
	margin: 0 -1.5rem;
	padding: 0.8125rem 1.5rem;
	white-space: nowrap;
	&:hover,
	&:focus {
		background: @color-primary--dark;
	}
	@media (min-width: @mq-m) {
		background: 0;
		color: @color-primary;
		&:hover,
		&:focus {
			background: 0;
			color: @color-primary--dark;
		}
	}
	@media (min-width: @mq-xl) {
		display: block;
		background: 0;
		color: currentColor;
		flex: 0 0 auto;
		margin: 1em 0;
		padding: 0;
		&:hover,
		&:focus {
			background: 0;
			color: @color-primary;
		}
	}
	svg {
		transform: scaleX(-1);
		order: -1;
		margin-right: 0.25rem;
	}
}
.store-header__detail {
	> * {
		display: flex;
		align-items: center;
		color: @color-primary;
	}
	.icon {
		margin-right: 0.5rem;
		flex-shrink: 0;
	}
	&--phone {
		a span {
			@media (min-width: @mq-xxl) {
				font-size: 1.35em;
			}
		}
	}
}
.store-header__cta {
	margin-left: auto;
	text-align: right;
}


/*-----------------------------------------------------------------------------------------
	/Hero
-----------------------------------------------------------------------------------------*/
.store-hero {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	margin: 0 -1.5rem 4rem;
	@media (min-width: @mq-m) {
		flex-direction: row;
		margin: 0 0 2rem -1.5rem;
	}
	@media (min-width: @mq-xl) {
		margin: 0 0 3.2rem -1.5rem;
	}
}
.store-hero__slides {
	padding-right: 0;
	position: relative;
	.store-header__heading {
		position: absolute;
		color: #fff;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		padding: 1rem 1.5rem;
		z-index: 2;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		background: linear-gradient(197.69deg, rgba(0, 0, 0, 0) 40.58%, rgba(0, 0, 0, 0.5) 100%);
		
		@media (min-width: @mq-m) {
			left: 1.5rem;
		}
		
		@media (min-width: @mq-xl) {
			display: none;
		}
	}
}
.store-hero__slide {
	background: @color-primary--light;
	@media (min-width: @mq-m) {
		border-radius: @border-radius 0 0 @border-radius;
	}
	&:before {
		content: "";
		float: left;
		padding-bottom: 70%;
	}
}

.store-hero__map {
	background: @color-primary--light;
	padding: 0;
	height: 100vw;
	max-height: 31.25rem;
	@media (min-width: @mq-m) {
		border-radius: 0 @border-radius @border-radius 0;
		height: auto;
		max-height: 100%;
		&:before {
			display: none;
		}
	}
}

.store-hero__mobile-details {
	padding: 1.5rem;
	.meta {
		.icon {
			width: 16px;
			height: 16px;
			margin-top: 3px;
			margin-bottom: auto;
			color: currentColor;
			background: 0;
		}
		a[href^="tel"] {
			.weight-bold;
		}
		.store-header__detail {
			font-size: 1.0625rem;
			> * {
				padding: 0.88em 1.17em;
				border: 1px solid @color-divider;
			}
			+ .store-header__detail  {
				margin-top: -1px;
			}
			&:first-child {
				> * {
					border-radius: @border-radius @border-radius 0 0;
				}
			}
			&:last-child {
				> * {
					border-radius: 0 0 @border-radius @border-radius;
				}
			}
		}
	}
	.store-header__cta {
		text-align: left;
		margin: 1.5rem 0 0;
		.button {
			display: inline-flex;
			font-size: 1rem;
		}
	}
	@media (min-width: @mq-m) {
		width: 100%;
		order: 3;
		display: flex;
		padding: 1.25rem 0 1.125rem;
		margin: 0 0 0 1.5rem;
		border-bottom: 1px solid @color-divider;
		.meta {
			display: flex;
			width: 100%;
		}
		.meta > * {
			padding-right: 1em;
		}
		.meta .store-header__detail > * {
			padding: 0;
			border: 0;
		}
		.store-header__detail--phone {
			width: (100% / 12 * 4);
			white-space: nowrap;
		}
		.store-header__detail--address {
			width: (100% / 12 * 6);
		}
		.store-header__cta {
			margin: 0 0 0 auto;
		}
	}
	@media (min-width: @mq-xl) {
		display: none;
	}
}

/*-----------------------------------------------------------------------------------------
	/Map
-----------------------------------------------------------------------------------------*/
.store-hero__map {
	position: relative;
	.map-zoom {
		top: auto;
		right: 1.5rem;
		bottom: 1.5rem;
		display: block;
	}
}
.store-hero__map-canvas {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}


/*-----------------------------------------------------------------------------------------
	/Details
-----------------------------------------------------------------------------------------*/
.store-details {
	margin: 0 0 4.5rem;
	.headings--block {
		margin: 0 0 1.5625rem;
	}
}
.store-details__hours {
	margin-top: 3.75rem;
	@media (min-width: @mq-m) {
		margin-top: 0;
	}
}
.store-hours table {
	width: 100%;
	text-align: left;
	border-top: 1px solid @color-divider;
	border-collapse: collapse;
	th,
	td {
		border-bottom: 1px solid @color-divider;
		padding: 0;
	}
	th,
	td:first-child {
		font-size: 0.75rem;
		text-transform: uppercase;
		width: 8rem;
		.weight-medium;
	}
	td {
		padding: 12px 0;
		.weight-bold;
	}
}


/*-----------------------------------------------------------------------------------------
	/Reviews
-----------------------------------------------------------------------------------------*/
.reviews {
	margin: 3rem 0 0;
}
.reviews__inner {
	@media (min-width: @mq-m) and (max-width: (@mq-l - 0.0625em)) {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
}
.reviews__layout {
	position: relative;
	@media (max-width: (@mq-m - 0.0625em)) {
		padding-bottom: 3rem;
	}
	@media (min-width: @mq-xl) {
		padding: 3rem 0 0;
		border-top: 1px solid @color-divider;
	}
}
.reviews__header {
	display: flex;
	align-items: center;
	margin: 0 0 1.5625rem;
	@media (min-width: @mq-m) {
		margin: 0 0 3rem;
	}
	.external-link {
		margin: 0 0 0 auto;
		@media (max-width: (@mq-m - 0.0625em)) {
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}
}
.reviews__list {
	border: 1px solid @color-divider;
	border-right: 0;
	border-bottom: 0;
	display: flex;
	flex-wrap: wrap;
	border-radius: @border-radius;
}
.review {
	border: 1px solid @color-divider;
	border-top: 0;
	border-left: 0;
	padding: 1.5rem;
	width: 100%;
	@media (min-width: @mq-m) {
		width: 50%;
		padding: 2.5rem;
	}
	footer {
		.small-caps;
		margin-top: 1em;
	}
	&:last-child {
		border-bottom-right-radius: @border-radius;
	}
	@media (max-width: (@mq-m - 0.0625em)) {
		&:first-child {
			border-radius: @border-radius @border-radius 0 0;
		}
		&:last-child {
			border-radius: 0 0 @border-radius @border-radius;
		}
	}
}


/*------------------------------------------------------------------------------
	/Contact store modal
------------------------------------------------------------------------------*/
.contact-modal {
	width: 100%;
	max-width: 53rem;
	padding: 1.5rem;
	
	@media (min-width: @mq-m) {
		padding: 4rem;
	
	}
}
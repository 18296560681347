.blog-article {
	&.has-hero {
		.blog-article-header {
			@media (min-width: @mq-m) {
				padding-bottom: 11rem;
			}
		}
	}
}

.blog-article-header {
	background: fade(@color-grey, 10%);
	@media (min-width: @mq-m) {
		padding: 4rem 0 7.125rem;
	}
	@media (min-width: @mq-xl) {
		padding-top: 7.125rem;
	}
}
.blog-article-header__layout {
	@media (min-width: @mq-m) {
		max-width: 40em;
		.mx-auto;
	}
}
.blog-article-header__text {
	padding: 2.5rem 0;
	@media (min-width: @mq-m) {
		padding: 0;
	}
}
.blog-article-header__nav-back {
	display: flex;
	align-items: center;
	background: @color-body;
	color: #fff;
	font-size: 0.75rem;
	line-height: 1.3;
	text-transform: uppercase;
	margin: 0 -1.5rem;
	padding: 0.8125rem 1.5rem;
	&:hover,
	&:focus {
		background: @color-primary;
		color: #fff;
	}
	@media (min-width: @mq-m) {
		display: block;
		background: 0;
		color: currentColor;
		flex: 0 0 auto;
		margin: 1em @gutter-width 2em 0;
		padding: 0;
		max-width: 80px;
		&:hover,
		&:focus {
			background: 0;
			color: @color-primary;
		}
	}
	@media (min-width: @mq-xl) {
		position: absolute;
		margin-left: -7rem;
	}
	svg {
		transform: scaleX(-1);
		order: -1;
		margin-right: 0.25rem;
	}
}
.blog-article-header__categories {
	.list-reset;
	margin: 1rem 0 0 -2px;
	display: flex;
	@media (min-width: @mq-m) {
		margin-top: 2rem;
	}
	li {
		margin: 2px;
	}
	a {
		background: @color-body;
		color: #fff;
		text-transform: uppercase;
		font-size: 0.75rem;
		padding: 0.4em 1em;
		border-radius: 1rem;
		&:hover,
		&:focus {
			background: @color-primary;
		}
	}
}

.blog-article-body__hero {
	max-width: 61rem;
	margin: 0 -1.5rem 0;
	@media (min-width: @mq-m) {
		margin: -7.5rem auto 0;
	}
}

.blog-article-body__content {
	max-width: 61rem;
	.mx-auto;
	margin-top: 2rem;
	@media (min-width: @mq-m) {
		margin-top: 4rem;
	}
}
/*-----------------------------------------------------------------------------------------
	/Less variables / mixins
-----------------------------------------------------------------------------------------*/
//Timestamp
	@date                            : '2021-01-20-153605';
	@timestamp                       : "?v=@{date}";

//Fonts
	@font-body                       : 'DIN Next™ W01', 'DIN Next W01', sans-serif;
	@font-headings                   : 'Stag Web', sans-serif;
	.weight-regular { font-weight    : 400; }
	.weight-medium { font-weight     : 500; }
	.weight-bold { font-weight       : 700; }

//Colors
	@color-body                      : #333;
	@color-primary                   : #005EB8;
	@color-primary--dark             : #003087;
	@color-primary--light            : tint(@color-primary, 92%);
	@color-secondary                 : #FFC60B;
	@color-tertiary                  : #EC9B00;
	// @color-quinary                   : #EC9B00;
	@color-red                       : #E0503D;
	@color-grey                      : #949494;
	@color-divider                   : tint(@color-body, 84%);
	
	@color-links                     : @color-primary;

//Easings
	@ease                            : cubic-bezier(.55,0,.1,1);
	@ease-quint 					 : cubic-bezier(0.86, 0, 0.07, 1);

//Breakpoints
	@mq-s                            : 31.25em;
	@mq-m                            : 43.75em;
	@mq-l                            : 62.5em;
	@mq-xl                           : 75em;
	@mq-xxl                          : 100em;
	
//Other
	@border-radius                   : 4px;

/*-----------------------------------------------------------------------------------------
	/Aspect ratios
-----------------------------------------------------------------------------------------*/
.aspect-ratio {
    height: 0;
    position: relative;
}
.aspect-ratio--16x9 { padding-bottom: 56.25%; }
.aspect-ratio--2x1 { padding-bottom: 50%; }
.aspect-ratio--4x3 { padding-bottom: 75%; }
.aspect-ratio--6x4 { padding-bottom: 66.6%; }
.aspect-ratio--4x6 { padding-bottom: 140%; }
.aspect-ratio--3x4 { padding-bottom: 117%; }
.aspect-ratio--1x1 { padding-bottom: 100%; }




/*-----------------------------------------------------------------------------------------
	/Mixins + utilities
-----------------------------------------------------------------------------------------*/
.scrollbars {
	&::-webkit-scrollbar {
	    width: 8px;
	}
	&::-webkit-scrollbar-track {
	    border-radius: 10em;
		// background: fade(black, 10%);
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 10em;
		background: @color-links;
	}
}
.webkit-scrolling { -webkit-overflow-scrolling: touch;}
.webkit-tap-color { -webkit-tap-highlight-color: transparent; -webkit-tap-highlight-color: rgba(0,0,0,0); }

.pseudo {
	content: "";
	display: block;
}
.visuallyhidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border-width: 0;
}
.invisible {
	visibility: hidden;
}
.hidden {
	display: none !important;
}




/*------------------------------------------------------------------------------
    /Grid - rows
------------------------------------------------------------------------------*/
.grid-row {
    display: flex;
	flex-wrap: wrap;
}

/*------------------------------------------------------------------------------
    /Grid - columns
------------------------------------------------------------------------------*/
.grid-col {
	width: 100%;
}
.grid-col--grow {
    flex: 1;
}
.has-gutters { margin-left: -20px; margin-bottom: -20px; }
.has-gutter { padding-left: 20px; padding-bottom: 20px; }
.has-gutters--y-l { margin-left: -20px; margin-bottom: -64px; }
.has-gutter--y-l { padding-left: 20px; padding-bottom: 64px; }
.has-gutters-s { margin-left: -10px; margin-bottom: -10px; }
.has-gutter-s { padding-left: 10px; padding-bottom: 10px; }

// .xs-1 { width: 8.3333333%; }
// .xs-2 { width: 16.6666666666%; }
// .xs-3 { width: 25%; }
// .xs-4 { width: 33.333333333%; }
// .xs-5 { width: 41.666666666%; }
// .xs-6 { width: 50%; }
// .xs-7 { width: 58.333333333%; }
// .xs-8 { width: 66.66666%; }
// .xs-9 { width: 75%; }
// .xs-10 { width: 83.333333333%; }
// .xs-11 { width: 91.6666666667%; }
// .xs-12 { width: 100%; }

// .xs-offset-0 { margin-left: 0; }
// .xs-offset-1 { margin-left: 8.333333333%; }
// .xs-offset-2 { margin-left: 16.6666666666%; }
// .xs-offset-3 { margin-left: 25%; }
// .xs-offset-8 { margin-left: 66.66666%; }


// @media(min-width: @mq-s) {
//     .s-1 { width: 8.3333333%; }
//     .s-2 { width: 16.6666666666%; }
//     .s-2b { width: 19.999999999%; }
//     .s-3 { width: 25%; }
//     .s-4 { width: 33.333333333%; }
//     .s-5 { width: 41.666666666%; }
//     .s-6 { width: 50%; }
//     .s-7 { width: 58.333333333%; }
//     .s-8 { width: 66.66666%; }
//     .s-9 { width: 75%; }
//     .s-10 { width: 83.333333333%; }
//     .s-11 { width: 91.6666666667%; }
//     .s-12 { width: 100%; }
//     .s-auto { width: auto; }

// 	.s-offset-0 { margin-left: 0; }
// 	.s-offset-1 { margin-left: 8.333333333% }
// 	.s-offset-2 { margin-left: 16.6666666666% }
// 	.s-offset-3 { margin-left: 25%; }
// 	.s-offset-8 { margin-left: 66.66666% }
// }
// @media(min-width: @mq-m) {
//     .m-1 { width: 8.3333333%; }
//     .m-2 { width: 16.6666666666%; }
//     .m-2b { width: 19.999999999%; }
//     .m-3 { width: 25%; }
//     .m-4 { width: 33.333333333%; }
//     .m-5 { width: 41.666666666%; }
//     .m-6 { width: 50%; }
//     .m-7 { width: 58.333333333%; }
//     .m-8 { width: 66.66666%; }
//     .m-9 { width: 75%; }
//     .m-10 { width: 83.333333333%; }
//     .m-11 { width: 91.6666666667%; }
//     .m-12 { width: 100%; }
//     .m-auto { width: auto; }

// 	.m-offset-0 { margin-left: 0; }
// 	.m-offset-1 { margin-left: 8.333333333% }
// 	.m-offset-2 { margin-left: 16.6666666666% }
// 	.m-offset-3 { margin-left: 25%; }
// 	.m-offset-8 { margin-left: 66.66666% }
// }
// @media(min-width: @mq-l) {
//     .l-1 { width: 8.3333333%; }
//     .l-2 { width: 16.6666666666%; }
//     .l-2b { width: 19.999999999%; }
//     .l-3 { width: 25%; }
//     .l-4 { width: 33.333333333%; }
//     .l-5 { width: 41.666666666%; }
//     .l-6 { width: 50%; }
//     .l-7 { width: 58.333333333%; }
//     .l-8 { width: 66.66666%; }
//     .l-9 { width: 74.9999999%; }
//     .l-10 { width: 83.333333333%; }
//     .l-11 { width: 91.6666666667%; }
//     .l-12 { width: 100%; }
//     .l-auto { width: auto; }

// 	.l-offset-0 { margin-left: 0; }
// 	.l-offset-1 { margin-left: 8.333333333% }
// 	.l-offset-2 { margin-left: 16.6666666666% }
// 	.l-offset-3 { margin-left: 25%; }
// 	.l-offset-8 { margin-left: 66.66666% }
// }
// @media(min-width: @mq-xl) {
//     .xl-1 { width: 8.3333333%; }
//     .xl-2 { width: 16.6666666666%; }
//     .xl-2b { width: 19.999999999%; }
//     .xl-3 { width: 25%; }
//     .xl-4 { width: 33.333333333%; }
//     .xl-5 { width: 41.666666666%; }
//     .xl-6 { width: 50%; }
//     .xl-7 { width: 58.333333333%; }
//     .xl-8 { width: 66.66666%; }
//     .xl-9 { width: 74.9999999%; }
//     .xl-10 { width: 83.333333333%; }
//     .xl-11 { width: 91.6666666667%; }
//     .xl-12 { width: 100%; }
//     .xl-auto { width: auto; }

// 	.xl-offset-0 { margin-left: 0; }
// 	.xl-offset-1 { margin-left: 8.333333333% }
// 	.xl-offset-2 { margin-left: 16.6666666666% }
// 	.xl-offset-3 { margin-left: 25%; }
// 	.xl-offset-8 { margin-left: 66.66666% }
// }
// @media(min-width: @mq-xxl) {
//     .xxl-1 { width: 8.3333333%; }
//     .xxl-2 { width: 16.6666666666%; }
//     .xxl-2b { width: 19.999999999%; }
//     .xxl-3 { width: 25%; }
//     .xxl-4 { width: 33.333333333%; }
//     .xxl-5 { width: 41.666666666%; }
//     .xxl-6 { width: 50%; }
//     .xxl-7 { width: 58.333333333%; }
//     .xxl-8 { width: 66.66666%; }
//     .xxl-9 { width: 74.9999999%; }
//     .xxl-10 { width: 83.333333333%; }
//     .xxl-11 { width: 91.6666666667%; }
//     .xxl-12 { width: 100%; }
//     .xxl-auto { width: auto; }

// 	.xxl-offset-0 { margin-left: 0; }
// 	.xxl-offset-1 { margin-left: 8.333333333% }
// 	.xxl-offset-2 { margin-left: 16.6666666666% }
// 	.xxl-offset-3 { margin-left: 25%; }
// 	.xxl-offset-8 { margin-left: 66.66666% }
// }


/*-----------------------------------------------------------------------------------------
	/Whitespace
-----------------------------------------------------------------------------------------*/
.section-inner {
	padding-left: 1.5rem;
	padding-right: 1.5rem;

	@media(min-width: @mq-l) {
		padding-left: 3.5rem;
		padding-right: 3.5rem;
	}
	// @media(min-width: @mq-l) {
	// 	padding-left: 5rem;
	// 	padding-right: 5rem;
	// }
	// @media(min-width: 100em) {
	// 	padding-left: 6rem;
	// 	padding-right: 6rem;
	// }
}
.section-inner--pb {
	padding-bottom: 4rem;

	@media(min-width: @mq-m) { padding-bottom: 5rem; }
	@media(min-width: @mq-xl) { padding-bottom: 7.5rem; }
}
.section-inner--pt {
	padding-top: 4rem;

	@media(min-width: @mq-m) { padding-top: 5rem; }
	@media(min-width: @mq-xl) { padding-top: 7.5rem; }
}
.section-inner--py {
	padding-top: 4rem;
	padding-bottom: 4rem;

	@media(min-width: @mq-m) { padding-top: 5rem; padding-bottom: 5rem; }
	@media(min-width: @mq-xl) { padding-top: 7rem; padding-bottom: 7.5rem; }
}
.col-pad--r {
	@media(min-width: @mq-l) {
		padding-right: 4.5rem;
	}
}
.col-pad--l {
	@media(min-width: @mq-l) {
		padding-left: 4rem;
	}
}
.negative-inner {
	margin-left: -2rem;
	margin-right: -2rem;

	@media(min-width: @mq-m) {
		margin-left: -4rem;
		margin-right: -4rem;
	}
	@media(min-width: @mq-l) {
		margin-left: -5rem;
		margin-right: -5rem;
	}
	@media(min-width: @mq-xxl) {
		margin-left: -6rem;
		margin-right: -6rem;
	}
}



/*-----------------------------------------------------------------------------------------
	/Whitespace - small
-----------------------------------------------------------------------------------------*/
.section-inner--s {
	padding-left: 2rem;
	padding-right: 2rem;

	@media(min-width: @mq-m) {
		padding-left: 3rem;
		padding-right: 3rem;
	}
	@media(min-width: @mq-l) {
		padding-left: 4rem;
		padding-right: 4rem;
	}
}
.section-inner--pb-s {
	padding-bottom: 2rem;

	@media(min-width: @mq-m) {
		padding-bottom: 3.125rem;
	}
	@media(min-width: @mq-l) {
		padding-bottom: 5rem;
	}
}
.section-inner--pt-s {
	padding-top: 2rem;

	@media(min-width: @mq-m) {
		padding-top: 3.125rem;
	}
	@media(min-width: @mq-l) {
		padding-top: 5rem;
	}
}
.section-inner--py-s {
	padding-top: 2rem;
	padding-bottom: 2rem;

	@media(min-width: @mq-m) {
		padding-bottom: 3.125rem;
		padding-top: 3.125rem;
	}
	@media(min-width: @mq-l) {
		padding-bottom: 5rem;
		padding-top: 5rem;
	}
}



/*-----------------------------------------------------------------------------------------
	/Blocks
-----------------------------------------------------------------------------------------*/
.block {
	margin-bottom: 3em;

	&:last-child {
		margin-bottom: 0;
	}
}



/*-----------------------------------------------------------------------------------------
	/Containment - max widths
-----------------------------------------------------------------------------------------*/
.mw-max {
	max-width: 93.75rem;
}
@media(min-width:43.75em) {
	.mw-1600 { max-width: 100rem; }
	.mw-1400 { max-width: 87.5rem; }
	.mw-1200 { max-width: 75rem; }
	.mw-1000 { max-width: 62.5rem; }
	.mw-800 { max-width: 50rem; }
	.mw-700 { max-width: 43.75rem; }
	.mw-40em { max-width: 40em; }
	.mw-30em { max-width: 30em; }
}


/*------------------------------------------------------------------------------
    /Bespoke padding + margins
------------------------------------------------------------------------------*/
.mx-auto {
	margin-right: auto;
	margin-left: auto;
}
.mt-1 { margin-top: 1em;}
.mt-2 { margin-top: 2em;}

@media(min-width: @mq-l) {
	.l\:pl-3 {
		padding-left: 3em;
	}
}


/*------------------------------------------------------------------------------
    /Utilities
------------------------------------------------------------------------------*/
.height-100 { height: 100%; }

/*-----------------------------------------------------------------------------------------
	/Global
-----------------------------------------------------------------------------------------*/
@field-padding: 1rem 1.5rem;
fieldset {
	border: none;
}
.input--txt,
.fui-input:not(.fui-checkbox-input, .fui-radio-input),
select,
textarea {
	position: relative;
	z-index: 2;
	border: 1px solid @color-divider;
	background: #f5f5f5;
	color: @color-body;
	padding: @field-padding;
	width: 100%;
	transition: background-color .2s ease-in-out, border .2s ease-in-out, box-shadow .2s ease-in-out;
	appearance: none;
	font-family: @font-body;
	font-size: 1rem;
	outline: none;
	border-radius: @border-radius;

	.bg-dark & {
		border: none;
		background: #fff;
	}
	&:hover,
	&:focus {
		border-color: @color-body;
		outline: 0;
	}
}

a.input--txt {
	display: block;
}
.form-field,
.fui-row {
	padding: 0;
	margin-bottom: 1rem;
}
.fui-row-empty {
	.visuallyhidden;
}

/*-----------------------------------------------------------------------------------------
	/Select
-----------------------------------------------------------------------------------------*/
select {
	height: auto;
	appearance: none;
	-webkit-appearance: none;
	cursor: pointer;
}
.select,
.fui-type-dropdown .fui-input-container {
	position: relative;

	&:before {
		content: "";
		background: url('/images/icons/icon--select.svg') no-repeat;
		position: absolute;
		z-index: 5;
		width: 13px;
		height: 9px;
		right: 0.9em;
		top: 50%;
		margin-top: -3.5px;
		pointer-events: none;
		transition: opacity .2s ease-in-out;
	}
}
select:hover,
select:focus {
	outline: none;
}
select::-ms-expand {
	display: none;
}
option:focus {
	// outline: none;
}
option {
	// color: @color-tertiary;
	color: @color-body;
}


/*-----------------------------------------------------------------------------------------
	/Textarea
-----------------------------------------------------------------------------------------*/
textarea {
	height: 13.5rem;
	display: block;
	resize: vertical;
}


/*-----------------------------------------------------------------------------------------
	/Labels
-----------------------------------------------------------------------------------------*/
.label,
.fui-label {
	font-size: 1em;
	display: block;
	margin-bottom: 0.7em;
}

/*-----------------------------------------------------------------------------------------
	/Validation
-----------------------------------------------------------------------------------------*/
.required {
	.visuallyhidden;
}
.validationErrors {
	.list-reset;
	margin-top: -2px;
}
.fui-alert-error {
	color: @color-red;
	margin: 0 0 1rem;
}
.formValidationError,
.fui-error-message {
	background: 0;
	color: @color-red;
	line-height: normal;
	font-size: 0.8125rem;
	overflow: hidden;
	width: auto;
	position: relative;
	padding: 0.75rem 0 0.6875rem;
	display: flex;
	align-items: center;
	font-weight: 500;

	.field--checkbox &,
	.field--choice & {
		// margin: 1em 0 0;
		// border-radius: @border-radius;
	}
}
.side-panel {
	color: @color-body;
	
	@media (max-width: (@mq-l - 0.0625em)) {
		display: flex;
		flex-direction: column;
		padding: 0;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 500px;
		max-width: 100vw;
		height: 100vh;
		height: 100dvh;
		z-index: 500;
		transform: translateX(-101%);
		transition: transform 500ms @ease;
		@supports (-webkit-touch-callout: none) {
			padding-bottom: 6rem;
		}
		.filters__list {

		}
		.filters {
			margin: 0;
			padding: 0;
			overflow-y: auto;
			flex: 1;
		}
		.filters__group,
		.filters__group + .filters__group {
			padding: 1.625rem 1.5rem;
			margin: 0;
		}
		.side-panel__header,
		.filters,
		.filters-actions {
			z-index: 2;
			background-color: #FFF;
		}
	}
}

.side-panel__header {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: 1.625rem 1.5rem 0;
	@media (min-width: @mq-l) {
		display: none;
	}
	.h {
		sup {
			width: 1rem;
			height: 1rem;
			margin: 0 0 0 3px;
			background: @color-body;
			color: #fff;
			border-radius: 50%;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-family: @font-body;
			font-size: 0.75rem;
			line-height: 1;
			.weight-bold;
		}
	}
	.close {
		width: 44px;
		height: 44px;
		margin-bottom: -10px;
		margin-right: -10px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.side-panel__overlay {
	position: fixed;
	z-index: 200;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: fade(black, 75%);
	visibility: hidden;
	opacity: 0;
	transition: 500ms @ease;
	@media (min-width: @mq-l) {
		display: none;
	}
}

//State
.side-panel-is-open {
	@media (max-width: (@mq-l - 0.0625em)) {
		overflow: hidden;
		.side-panel {
			transform: none;
		}
		.side-panel__overlay {
			opacity: 1;
			visibility: visible;
		}
	}
}
.template--student-beds {
	.header {
		border-bottom: 0;
	}
}

.s-beds__header {
	@media (min-width: @mq-l) {
		display: flex;
	}
}
.s-beds__intro {
	
	// height: 100vh;
	// height: calc(100vh - @header-height--mobile);
	display: flex;
	flex-direction: column;
	color: #fff;
	@media (min-width: @mq-m) {
		position: relative;
	}
	@media (min-width: @mq-l) {
		width: 65%;
		// height: calc(100vh - @header-height--mobile);
		height: 100vh;
		position: sticky;
		top: 0;
	}
	@media (min-width: @mq-xxl) {
		// height: calc(100vh - @header-height);
	}
	@media (min-width: @mq-xxl) {
		width: 72.5%;
	}
}
.s-beds__hero {
	height: 100%;
	position: relative;
	min-height: calc(100vh - @header-height--mobile);
	display: flex;
	flex-direction: column;
	@media (min-width: @mq-m) {

	}
	@media (min-width: @mq-l) {
		min-height: 0;
	}
}
.s-beds__hero-media {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;
	iframe,
	video {
		object-fit: cover;
		object-position: 0;
		background: @color-primary--dark;
		width: auto;
		height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
		height: 42.2866vw;
		min-height: 100vh;
		min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
		min-width: 236.48148vh;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	@media (min-width: @mq-l) {
		height: 100vh;
	}
}
.s-beds__hero-content {
	flex: 1;
	position: relative;
	z-index: 2;
	padding: 4rem 1.5rem 2.25rem;
	display: flex;
	flex-direction: column;
	header {
		max-width: 450px;
		max-width: 45ch;
		@media (min-width: @mq-m) {
			max-width: 50ch;
		}
	}
	.heading--page {
		@media (min-width: @mq-m) {
			font-size: 2.875rem;
		}
	}
	@media (min-width: @mq-m) {
		padding: 4rem 3.5rem;
	}
	@media (min-width: @mq-xxl) {
		padding: 6rem 3.5rem;
	}
}
.s-beds__hero-ctas {
	margin: auto 0 0;
	display: flex;
	flex-direction: column;
	@media (min-width: @mq-m) {
		margin: 0;
		align-items: flex-start;
	}
}
.s-beds__hero-cta {
	border-radius: @border-radius;
	min-height: 3rem;
	&:first-child {
		@media (max-width: (@mq-m - 0.0625em)) {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
	&:last-child {
		@media (max-width: (@mq-m - 0.0625em)) {
			border-radius: @border-radius;
		}
	}
	& + & {
		@media (max-width: (@mq-m - 0.0625em)) {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
		@media (min-width: @mq-m) {
			margin-top: 2px;
		}
	}
	&:focus {
		outline: 0;
	}
}
.s-beds__hero-cta--message {
	background: @color-primary;
	color: #fff;
	display: flex;
	align-items: center;
	font-size: 1em;
	padding: 1em 1.5em;
	.button-label {
		.weight-bold;
	}
	.icon + .button-label {
		margin-left: 1em;
	}
	.icon--desktop {
		display: none;
		@media (min-width: @mq-m) {
			display: flex;
		}
	}
	.icon--mobile {
		@media (min-width: @mq-m) {
			display: none;
		}
	}
	&:hover,
	&:focus {
		background: @color-primary--dark;
	}
	@media (min-width: @mq-m) {
		display: inline-flex;
	}
}
.s-beds__video {
	padding: 2.625rem 1.5rem 2.625rem 1.25rem;
	background: @color-primary;
	color: #fff;
	width: 100%;
	display: flex;
	align-items: center;
	
	&:hover,
	&:focus {
		outline: 0;
		.s-beds__video-thumb .icon {
			transform: scale(1.2);
			@media (min-width: @mq-l) {
				background: @color-primary;
				color: #fff;
			}
		}
		
	}
	
	@media (min-width: @mq-m) {
		position: absolute;
		z-index: 2;
		left: 3.5rem;
		right: 3.5rem;
		bottom: 5rem;
		padding: 0;
		background: 0;
		width: auto;
		max-width: 30rem;
	}
	@media (min-width: @mq-xl) {
		// bottom: 3.5rem;
	}
	@media (min-width: @mq-xxl) {
		left: 3.5rem;
    right: 5rem;
    bottom: 7.25rem;
	}
}
.s-beds__video-thumb {
	position: relative;
	figure {
		width: 123px;
		height: 123px;
		border-radius: 50%;
		@media (min-width: @mq-xl) {
			width: 158px;
			height: 158px;
		}
	}
	.icon {
		background: @color-secondary;
		color: @color-primary--dark;
		width: 40px;
		height: 40px;
		position: absolute;
		left: 50%;
		margin-left: -20px;
		bottom: -18px;
		transition: transform .25s @ease;
		svg {
			@media (max-width: (@mq-xl - 0.0625em)) {
				width: 16px;
				height: 16px;
			}
		}
	}
	+ .s-beds__video-text {
		margin-left: 1.3125rem;
		@media (min-width: @mq-xl) {
			margin-left: 1.5625rem;
		}
	}
}
.s-beds__video-text {
	flex: 1;
}
.s-beds__video-prefix {
	margin: 0 0 0.5em;
}
.s-beds__video-heading {
	@media (min-width: @mq-xl) {
		font-size: 1.5625rem;
	}
}

.s-beds__blocks {
	flex: 1;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	@media (min-width: @mq-m) {
		padding: 3.5rem;
	}
	@media (min-width: @mq-l) {
		padding: 0 3rem;
	}
	@media (min-width: @mq-xl) {
		padding-right: 3.5rem;
	}
}
.s-beds__block {
	line-height: 1.5;
	
	&:not(:last-child) {
		margin: 0 0 3.5rem;
	}
	.heading--block {
		line-height: 1.5;
	}
}
.s-beds__block-media {
	margin: 0 0 2rem;
	@media (min-width: @mq-l) {
		margin-right: -3rem;
	}
	@media (min-width: @mq-xl) {
		margin-right: -3.5rem;
	}
}
.s-beds__block-date {
	margin: 0 0 0.25em;
}


// Shop
.s-beds__shop {
	overflow: hidden;
}
.s-beds__shop-layout {
	@media (max-width: (@mq-l - 0.0625em)) {
		padding-top: 2em;
	}
}
.s-beds__shop-header {
	margin: 0 0 1.25rem;
	header {
		display: flex;
		align-items: center;
		white-space: nowrap;
		.icon {
			margin: 0 0.625em 0 0;
			width: 38px;
			height: 38px;
			@media (min-width: @mq-m) {
				width: 48px;
				height: 48px;
				margin: 0 1em 0 0;
				svg {
					width: 23px;
					height: auto;
				}
			}
		}
	}
	@media (min-width: @mq-m) {
		margin: 0 0 3rem;
	}
	
}
.s-beds__shop-header-layout {
	@media (min-width: @mq-l) {
		display: flex;
		align-items: center;
	}
}
.s-beds__shop-filters {
	min-width: 100vw;
	overflow-x: auto;
	margin: 1em -1.5rem 0;
	padding: 0 0 0 1.5rem;
	
	.filters__list {
		display: flex;
	}
	.filter {
		padding: 0 1rem 0 0;
		white-space: nowrap;
	}
	
	@media (min-width: @mq-m) {
		margin: 1em 0 0;
		padding: 0;
		min-width: 0;
		width: auto;
		flex-wrap: wrap;
	}
	
	@media (min-width: @mq-l) {
		margin: 0.25em 0 0 1.625rem;
	}
}
.s-beds__shop-start {
	opacity: 0.5;
	margin: 0.5em 0 0;
	font-size: 1.125rem;
	@media (min-width: @mq-m) {
		font-size: 1.3125rem;
	}
	@media (min-width: @mq-l) {
		margin: 0.5em 0 0 2.7em;
	}
}
.s-beds__shop-results {
	position: relative;
}
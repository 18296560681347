.contact__text {
	@media (max-width: (@mq-l - 0.0625em)) {
		max-width: 25.8125rem;
	}
	+ .contact__form {
		@media (max-width: (@mq-l - 0.0625em)) {
			margin-top: 3rem;
			max-width: 33.5rem;
		}
	}
}
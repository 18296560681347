.c-card {
	
}
.c-card__link {
	color: currentColor;
	&:hover,
	&:focus {
		.c-card__heading {
			color: @color-primary;
		}
	}
}
.c-card__media {
	margin: 0 0 1.25rem;
	.img-resizer {
		.aspect-ratio--4x3;
	}
}
.c-card__prefix {
	margin: 0 0 0.375rem;
}
.c-card__heading {
	margin: 0 0 0.56em;
	transition: .15s;
}
.c-card__read-more {
	margin: 1em 0 0;
}
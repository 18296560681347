button{
	appearance: none;
	background: transparent;
	border: 0;
	padding: 0;
	color: currentColor;
	cursor: pointer;
	&:focus {
		outline: 0;
	}
}
.button {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: auto;
	padding: 0.5rem 1.5rem;
	font-family: @font-headings;
	font-size: 1rem;
	.weight-regular;
	border: none;
	border-radius: @border-radius;
	box-shadow: none;
	outline: 0;
	text-align: center;
	cursor: pointer;
	white-space: nowrap;
	
	transition-duration: .3s;
	transition-property:
	background-color,
	border,
	box-shadow,
	color,
	transform;
	transition-timing-function: @ease;
	
	@media (min-width: @mq-m) {
		font-size: 1.1875rem;
		padding: 0.75rem 1.75rem;
		min-height: 3.5rem;
	}
	
	@media (min-width: @mq-l) {
		font-size: 1.4375rem;
		padding: 1rem 2rem;
		min-height: 4rem;
	}
	
	.icon {
		svg {
			// width: 100%;
			max-width: 100%;
			height: auto;
		}
		&:last-child {
			margin-left: 0.5rem;
		}
		&:first-child {
			margin-left: 0;
			+ * {
				margin-left: 0.5rem;
			}
		}
	}

	&:disabled {
		cursor: default;
		user-select: none;
		pointer-events: none;
		opacity: .5;
		background: #cecece !important;
		border-color: transparent !important;
		color: #000 !important;
	}
	&:hover,
	&:focus {
		outline: 0;
		// box-shadow: 0 0 0 5px fade(@color-body,20%);
	}

	@media(min-width: @mq-s) {
		display: inline-flex;
		width: auto;
	}
}
.button-label {
	display: inline-block;
	vertical-align: middle;
}



.button-group {
	.list-reset;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	> li {
		width: 100%;
		@media (min-width: @mq-m) {
			width: auto;
		}
	}
	.button {
		margin: 4px;
	}
}
.button-group--centre {
	justify-content: center;
}


/*-----------------------------------------------------------------------------------------
	/Button variants
-----------------------------------------------------------------------------------------*/
.button--primary {
	background: @color-primary;
	color: #fff;
	min-height: 2.75rem;
	&:hover,
	&:focus {
		color: #fff;
		background: @color-primary--dark;
	}
}

.button--secondary {
	background: @color-secondary;
	color: @color-primary--dark;
	min-height: 2.75rem;
	&:hover,
	&:focus {
		color: #fff;
		background: @color-primary;
	}
}

.button--white {
	background: #fff;
	color: @color-primary;
}

.button--small {
	font-family: @font-body;
	text-transform: uppercase;
	font-size: 0.75rem;
	.weight-bold;
	padding: 0.8125rem 1.5rem;
	padding: 1.08em 1.85em;
	min-height: 2.75rem;
}

.button--link {
	font-family: @font-body;
	font-size: 1em;
	line-height: 1.4;
	color: @color-primary;
	padding: 0.5em 0;
	min-height: auto;
	.button-label {
		border-bottom: 1px solid transparent;
		transition: .15s;
	}
	&:hover,
	&:focus {
		.button-label {
			border-bottom-color: currentColor;
		}
	}
}

.button--clear {
	border: 1px solid @color-divider;
	color: currentColor;
}

.button--download {
	font-family: @font-body;
	font-size: 1em;
	background: #fff;
	color: @color-primary;
	border: 1px solid @color-primary--light;
	padding: 0;
	min-height: auto;
	
	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1rem;
		border-right: 1px solid @color-primary--light;
	}
	.button-label {
		padding: 1rem 1.5rem 1rem 1rem;
	}
	&:hover,
	&:focus {
		background: @color-primary;
		color: #fff;
		border-color: @color-primary;
		.icon {
			border-right-color: fade(#fff, 16%);
		}
	}
}

.button--compact-mobile {
	@media (max-width: (@mq-m - 0.0625em)) {
		height: 2.5rem;
		display: inline-flex;
		font-family: @font-body;
		text-transform: uppercase;
		font-size: 0.75rem;
		line-height: 1.333;
		.weight-bold;
	}
}

.button--skip {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 3000;
	border-radius: 0;
	background: @color-body;

	&:focus {
		width: auto;
		height: auto;
		padding: inhert;
		margin: 0;
		overflow: hidden;
		clip: auto;
	}
}
/*-----------------------------------------------------------------------------------------
	/Headings
-----------------------------------------------------------------------------------------*/
h1, .heading--page,
h2, .heading--section,
h3, .heading--block {
	font-family: @font-headings;
	.weight-regular;
}
.heading--page {
	font-size: 1.9375rem;
	line-height: 1.2;
	.weight-medium;

	@media(min-width: @mq-xl) {
		font-size: 2.875rem;
	}
}
.headings--page {
	margin-bottom: 2rem;
}


.heading--section {
	font-size: 1.4375rem;
	line-height: 1.22;
	@media (min-width: @mq-l) {
		font-size: 1.9375rem;
		line-height: 1.3;
	}
}
.headings--section {
	margin-bottom: 1rem;
}
.headings--section-l {
	margin-bottom: 2.5rem;
}


.heading--block {
	font-size: 1.4375rem;
	line-height: 1.2;
	.weight-regular;
}
.headings--block {
	margin-bottom: 1rem;
}

.heading--caps {
	font-size: 0.75rem;
	line-height: 1.333;
	text-transform: uppercase;
	.weight-medium;
	@media (min-width: @mq-m) {
		font-size: 1.0625rem;
		line-height: 1.4;
	}
}
// .heading--small-caps {
// 	font-size: 0.75rem;
// 	line-height: 1.333;
// 	text-transform: uppercase;
// 	.weight-medium;
// }



/*-----------------------------------------------------------------------------------------
	/Global type control
-----------------------------------------------------------------------------------------*/
.small-caps {
	font-size: 0.75rem;
	line-height: 1.333;
	text-transform: uppercase;
	.weight-medium;
}
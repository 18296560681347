

/*  --------------------------------------------------------------------------------
	/Grid variables
	----------------------------------------------------------------------------- */

	@half-gutter-width  : 1.5rem;
	@gutter-width       : @half-gutter-width * 2;
	@gutter-compensation: 0;
	@outer-margin       : 3.375rem;
	@grid-columns       : 12;
	
	// @col-names: s, m, l, xl, xxl;
	@col-breaks: @mq-s, @mq-m, @mq-l, @mq-xl, @mq-xxl;
	
	
	
	
	/*  --------------------------------------------------------------------------------
		/ Grid class names
	
		.row
		  .col-sm-1
		  .col-sm-offset-1
		----------------------------------------------------------------------------- */
	
	
	
	
	/*  --------------------------------------------------------------------------------
		/CSS Grid - global mixin
		----------------------------------------------------------------------------- */
	// .global-grid {
	// 	display: grid;
	// 	grid-template-columns: repeat(@grid-columns, 1fr);
	// 	grid-template-rows: auto;
	// 	gap: @gutter-width;
	// 	grid-gap: @gutter-width;
	// }
		
	
	/*------------------------------------------------------------------------------
			/Grid - rows
	------------------------------------------------------------------------------*/
	.grid-row {
		display: flex;
		flex-wrap: wrap;
		@media (min-width: @mq-l) {
			margin-left: -@half-gutter-width;
			margin-right: -@half-gutter-width;
		}
	}
	
	.cancel-grid-row {
		@media (min-width: @mq-l) {
			margin-left: -@half-gutter-width;
			margin-right: -@half-gutter-width;
		}
	}

	/*------------------------------------------------------------------------------
		/Grid - columns
	------------------------------------------------------------------------------*/
	.grid-col {
		width: 100%;
	}

	.grid-col--grow {
		flex: 1;
	}
		
		
	/*  --------------------------------------------------------------------------------
		/Grid Mixins
		----------------------------------------------------------------------------- */
	
	// .for
	.for(@i, @n) {.-each(@i)}
	.for(@n)     when (isnumber(@n)) {.for(1, @n)}
	.for(@i, @n) when not (@i = @n)  {
		.for((@i + (@n - @i) / abs(@n - @i)), @n);
	}
	
	// .for-each
	.for-each(@array)   when (default()) {.for-impl_(length(@array))}
	.for-impl_(@i) when (@i > 1)    {.for-impl_((@i - 1))}
	.for-impl_(@i) when (@i > 0)    {.-each(extract(@array, @i))}
	
	
	.makegrid(@class) {
		[class^='col-@{class}'],
		[class*=' col-@{class}'],
		[class^='col-@{class}-offset'],
		[class*='col-@{class}-offset']{
			flex: 0 0 auto;
			flex-direction: column;
			padding-right: @half-gutter-width;
			padding-left: @half-gutter-width;
		}
	
		.col-@{class}-0{
			padding-right: 0;
			padding-left: 0;
		}
	
		.col-@{class} {
			flex-grow: 1;
			flex-basis: 0;
			max-width: 100%;
		}
	
		.make-cols(@class);
		.make-offset(@class; 0);
		.make-offsets(@class);
		.make-position-classes(@class);
	}
	
	.make-column(@class; @col-number) {
		.col-@{class}-@{col-number} {
			flex-basis: ((100% / @grid-columns) * @col-number);
			max-width: ((100% / @grid-columns) * @col-number);
		}
	}
	
	.make-cols(@class; @col-number: @grid-columns) when (@col-number > 0) {
		.make-cols(@class; (@col-number - 1));
		.make-column(@class; @col-number);
	}
	
	.make-offset(@class; @col-number) {
		.col-@{class}-offset-@{col-number} {
			margin-left: ((100% / @grid-columns) * @col-number);
		}
	}
	
	.make-offsets(@class; @col-number: (@grid-columns - 1)) when (@col-number > 0) {
		.make-offsets(@class; (@col-number - 1));
		.make-offset(@class; @col-number);
	}
	
	.make-position-classes(@class) {
		// .start-@{class} {
		//     justify-content: flex-start;
		//     text-align: left;       //IE11 support
		// 	text-align: start;
		// }
		// .center-@{class} {
		// 	justify-content: center;
		// 	text-align: center;
		// }
		// .end-@{class} {
		//     justify-content: flex-end;
		//     text-align: right;      //IE11 support
		// 	text-align: end;
		// }
		// .top-@{class} {
		// 	align-items: flex-start;
		// }
		// .middle-@{class} {
		// 	align-items: center;
		// }
		// .bottom-@{class} {
		// 	align-items: flex-end;
		// }
		// .around-@{class} {
		//     justify-content: space-around;
		// }
		// .between-@{class} {
		//     justify-content: space-between;
		// }
		// .first-@{class} {
		// 	order: -1;
		// }
		// .last-@{class} {
		// 	order: 1;
		// }
	}
	
	
	
	 /* ----------------------------------------------------------------------------
		/Grid
		----------------------------------------------------------------------------- */
	
	.makegrid(xs);
	
	@media only screen and (min-width: @mq-s) {
	  .makegrid(s);
	}
	
	@media only screen and (min-width: @mq-m) {
	  .makegrid(m);
	}
	
	@media only screen and (min-width: @mq-l) {
	  .makegrid(l);
	}
	
	@media only screen and (min-width: @mq-xl) {
		.makegrid(xl);
	}
	
	@media only screen and (min-width: @mq-xxl) {
		.makegrid(xxl);
	}
	
	/*.for-each(@col-breaks);
	.-each(@breakpoint) {
		@media only screen and (min-width: @breakpoint) {
			.makegrid(extract(@col-names, @i));
		}
	}*/
	
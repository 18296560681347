

.header {
	background: #fff;
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid @color-divider;
	height: @header-height--mobile;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 100;
	transition: transform .35s @ease, height .35s @ease;
	@media (min-width: @mq-l) {
		height: @header-height--tablet;
	}
	@media (min-width: @mq-xl) {
		height: @header-height;
	}
}
.header__inner { 
	
}


/*------------------------------------------------------------------------------
	/Top bar
------------------------------------------------------------------------------*/
.header__bar {
	background: @color-primary;
	color: #fff;
	height: @header-bar-height;
	display: none;
	@media (min-width: @mq-xl) {
		display: block;
	}
}
.header__bar-inner {
	display: flex;
}

.header__bar-nav {
	.list-reset;
	display: flex;
}
.header__bar-nav-item {
	& + & {
		border-left: 1px solid fade(#fff, 16%);
	}
}
.header__bar-nav-link {
	display: flex;
	align-items: center;
	font-size: 0.75rem;
	line-height: 1.333;
	letter-spacing: 0.005rem;
	.weight-medium;
	color: #fff;
	text-transform: uppercase;
	padding: 0.5rem 1rem;
	height: 100%;
	.icon:first-child {
		margin-right: 0.25rem;
	}
	&[href^="tel:"] {
		.header__bar-nav-label {
			.weight-bold;
			font-size: 15px;
			line-height: 0.9333;
		}
	}
	&:hover,
	&:focus {
		background: @color-primary--dark;
	}
	&.is-active {
		background: @color-secondary;
		color: @color-primary--dark;
	}
}

.header__bar-nav--ctas {
	margin: 0 0 0 auto;
	display: flex;
}

/*------------------------------------------------------------------------------
	/Main header area
------------------------------------------------------------------------------*/
.header__main {
	flex: 1;
	display: flex;
	align-items: center;
	// padding: 2rem 0;
}
.header__main-inner {
	width: 100%;
	display: flex;
	align-items: center;
}
.header__logo {
	margin: 0 3rem 0 0;
	flex-shrink: 0;
	width: 108px;
	@media (min-width: @mq-xl) {
		width: 160px;
		img {
			width: auto;
			height: auto;
		}
	}
	@media (min-width: @mq-xxl) {
		margin: 0 6rem 0 0;
	}
	&:focus {
		outline: 0;
	}
}

/*------------------------------------------------------------------------------
	/Main nav
------------------------------------------------------------------------------*/
.main-nav {
	
}
.main-nav__inner {}

.main-nav-items {
	display: flex;
	align-items: center;
}
.main-nav-item {
	& + & {
		margin-left: 1.25rem;
		@media(min-width: 65.625em) {
			margin-left: 2rem;
		}
	}
	&--sale {
		.main-nav-link {
			color: @color-red;
		}
	}
}
.main-nav-link {
	font-family: @font-headings;
	color: @color-primary;
	@media (min-width: @mq-xxl) {
		font-size: 1.4375rem;
		line-height: 1.4;
	}
	&.is-active,
	&:focus {
		outline: 0;
		.main-nav__label:after {
			opacity: 0.24;
		}
	}
}
.main-nav__label {
	position: relative;
	&:after {
		content: "";
		height: 2px;
		background: currentColor;
		position: absolute;
		left: 0;
		right: 0;
		bottom: -0.25em;
		opacity: 0;
		transition: opacity .2s;
	}
	&:hover {
		&:after {
			opacity: 0.24;
		}
	}
}

.main-nav-toggle {
	width: 44px;
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: @color-links;
	margin-right: -10px;
	@media (min-width: @mq-xl) {
		display: none;
	}
}

/*------------------------------------------------------------------------------
	/Tools
------------------------------------------------------------------------------*/
.header__tools {
	display: flex;
	align-items: center;
	margin: 0 0 0 auto;
	padding: 0 0 0 2em;
}


/*------------------------------------------------------------------------------
	/Search
------------------------------------------------------------------------------*/
.header__search {
	+ .header__cart {
		@media (min-width: @mq-searchMobile) {
			margin-left: 1em;
		}
	}
}
.search__ui--default {
	display: none;
	@media (min-width: @mq-searchMobile) {
		display: block;
	}
}
@mq-searchMobile : 1400px;

.search__ui--mobile {
	display: block;
	background: #fff;
	padding-bottom: 1.5rem;
	position: sticky;
	top: 0;
	z-index: 3;
	@media (min-width: @mq-searchMobile) {
		display: none;
	}
	.search__field {
		width: 100%;
	}
	.search__input {
		background: @color-primary--light;
	}
}
.search__toggle {
	color: @color-primary;
	width: 44px;
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (min-width: @mq-searchMobile) {
		display: none;
	}
	.icon--close {
		display: none;
	}
}
.search__field {
	--height: 56px;
	position: relative;
	width: 18.5rem;
	height: var(--height);
	color: @color-primary;
	svg {
		position: absolute;
		top: 1rem;
		left: 1rem;
	}
}
.search__input {
	width: 100%;
	height: 100%;
	padding: 1rem var(--height) 1rem 3.375rem;
	border: 0;
	appearance: none;
	font-size: 1rem;
	color: currentColor;
	&::placeholder {
		color: currentColor;
		opacity: 0.5;
		transition: opacity .15s;
	}
	&:focus,
	&:hover {
		outline: 0;
		&::placeholder {
			opacity: 0.75;
		}
	}
}
.search__loading {
	all: unset;
	position: absolute;
	top: 0;
	right: 0;
	width: var(--height);
	height: var(--height);
	display: grid;
	place-items: center;
	&:not(.htmx-request) {
		opacity: 0;
		visibility: hidden;
	}
}
.search__panel {
	position: fixed;
	z-index: 5;
	top: @header-height--mobile;
	left: 0;
	right: 0;
	margin: -1px 0 0;
	height: calc(100vh - @header-height--mobile + 1px);
	opacity: 0;
	pointer-events: none;
	// overflow-y: auto;
	// background: #fff;
	// @media (min-width: @mq-m) {
	// 	background: 0;
	// }
	@media (min-width: @mq-l) {
		top: @header-height--tablet;
		height: calc(100vh - @header-height--tablet + 1px);
	}
	@media (min-width: @mq-xl) {
		top: @header-height;
		height: calc(100vh - @header-height + 1px);
	}
	@media (min-width: @mq-searchMobile) {
		top: @header-height;
		height: calc(100vh - @header-height);
		margin: 0;
	}
}
.search__panel-overlay {
	
	@media (min-width: @mq-m) {
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: fade(#000, 75%);
	}
}
.search__panel-inner {
	width: 100%;
	height: 100%;
	// background: #fff;
	overflow-y: auto;
	position: relative;
	z-index: 2;
	@media (min-width: @mq-searchMobile) {
		height: auto;
	}
}
.search__panel-results {
	background: #fff;
	@media (min-width: @mq-searchMobile) {
		overflow-y: auto;
		max-height: calc(100vh - @header-height);
		
		&::-webkit-scrollbar {
			width: 17px;
			background: #fff;
		}
		&::-webkit-scrollbar-track {
			// border-radius: 10em;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 10em;
			background: fade(black, 20%);
			border: 5px solid white;
		}
	}
}

.search__header {
	display: flex;
	align-items: center;
	margin: 0 0 2rem;
	.button {
		margin-left: auto;
		@media (max-width: (@mq-m - 0.0625em)) {
			.small-caps;
		}
	}
}

// Products
.search__products {
	padding-top: 0.5rem;
	padding-bottom: 2rem;
	@media (min-width: @mq-m) {
		padding-top: 3.5rem;
		padding-bottom: 3.5rem;
	}
	+ .search__pages {
		margin: 1.75rem 0 0;
		border-top: 1px solid @color-divider;
	}
}
.search__products-list {
	display: flex;
	flex-wrap: wrap;
	margin-left: -1.5rem;
	margin-bottom: -3rem;
	@media (min-width: @mq-l) {
		flex-wrap: nowrap;
		margin-bottom: 0;
	}
	@media (min-width: @mq-searchMobile) {
		margin-left: -3rem;
	}
	.c-prod-card {
		padding-left: 3rem;
		padding-left: 1.5rem;
		width: 50%;
		flex: 0 1 50%;
		margin: 0 0 1rem;
		@media (min-width: @mq-l) {
			width: 25%;
			flex: 0 1 25%;
			margin: 0;
		}
		@media (min-width: @mq-searchMobile) {
			padding-left: 3rem;
		}
		&:nth-child(5) {
			display: none;
			@media (min-width: @mq-searchMobile) {
				display: block;
			}
		}
	}
	.c-prod-card__text {
		padding-right: 0;
	}
	.c-prod-card__heading {
		font-size: 1rem;
		line-height: 1;
		@media (min-width: @mq-m) {
			font-size: 1.1875rem;
		}
	}
	.c-prod-card__price-range {
		@media (max-width: (@mq-m - 0.0625em)) {
			font-size: 0.8125rem;
		}
	}
}


// Pages
.search__pages {
	padding-top: 2rem;
	padding-bottom: 2rem;
	@media (min-width: @mq-m) {
		padding-top: 3.5rem;
		padding-bottom: 3.5rem;
	}
}
.search__pages-list {
	margin: 0 0 -2rem;
	@media (min-width: @mq-m) {
		display: flex;
		flex-wrap: wrap;
		margin-left: -3rem;
	}
	@media (min-width: @mq-l) {
		flex-wrap: nowrap;
		margin-bottom: 0;
	}
}
.search__pages-item {
	width: 100%;
	margin: 0 0 2rem;
	@media (min-width: @mq-m) {
		width: 50%;
		padding-left: 3rem;
	}
	@media (min-width: @mq-l) {
		margin: 0;
	}
	.c-card__link {
		color: @color-links;
		.c-card__text .c-card__heading {
			color: currentColor;
		}
		&:hover,
		&:focus {
			color: @color-tertiary;
		}
	}
	.c-card__prefix {
		color: @color-body;
	}
	.c-card__heading {
		@media (max-width: (@mq-l - 0.0625em)) {
			font-size: 1rem;
		}
	}
	.c-card__read-more {
		@media (max-width: (@mq-l - 0.0625em)) {
			display: none;
		}
	}
	.highlight {
		background: @color-primary;
		color: #fff;
	}
	
}


//State
.search-is-active {
	overflow: hidden;
	touch-action: none;
	margin-right: 17px;
	.container {
		overflow: hidden;
		touch-action: none;
	}
	.search__toggle {
		.icon--default {
			display: none;
		}
		.icon--close {
			display: flex;
		}
	}
	.search__panel {
		transition: opacity .15s;
		transform: none;
		pointer-events: auto;
		opacity: 1;
	}
}

/*------------------------------------------------------------------------------
	/Cart panel
------------------------------------------------------------------------------*/
.header__cart{
	position: relative;
	z-index: 10;
	margin-top: -9px;
}

.header__cart-toggle {
	position: relative;
	color: @color-primary;
	width: 44px;
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	.icon {
		position: relative;
	}
	&:hover,
	&:focus {
		color: @color-primary--dark;
	}
}
.header__cart-count {
	position: absolute;
	top: 0;
	right: 0;
	background: @color-red;
	color: #fff;
	width: 1rem;
	height: 1rem;
	line-height: 1rem;
	font-size: 0.75rem;
	.weight-bold;
	border-radius: 50%;
}

.cart-added {
	position: absolute;
	top: 140%;
	right: -1.4rem;
	padding: 2rem;
	width: 19rem;
	color: white;
	background-color: @color-primary;
	border-radius: .25rem;
	pointer-events: none;
	box-shadow: 0px 16px 22px 0 rgba(0, 0, 0, 0.14), 0px 33px 22px -30px rgba(0, 0, 0, 0.06);
	opacity: 0;
	transform: translateY(1rem);
	transition: 240ms;
	z-index: 3;

	&.visible{
		opacity: 1;
		pointer-events: initial;
		transform: translateY(0);
	}

	&:before{
		content: '';
		position: absolute;
		top: -.375rem;
		right: 2.5rem;
		width: 1rem;
		height: 1rem;
		background-color: @color-primary;
		border-radius: .25rem;
		transform: rotate(45deg);
	}
}

.cart-added__buttons{
	display: flex;
	margin-top: 1rem;
}

.cart-added__button--cart.button {
	width: 7.5rem;
	height: 2.5rem;
	min-height: 2.75rem;
	font-family: @font-body;
	font-size: .75rem;
	text-transform: uppercase;
	.weight-medium;

	&:hover, &:focus{
		.button--primary;
	}
}

.cart-added__button--close.button{
	width: 2.75rem;
	height: 2.75rem;
	min-height: 2.75rem;
	padding: 0;
	margin-left: .5rem;
	border: 1px solid fade(white, 16%);
}


/*------------------------------------------------------------------------------
	/Mobile nav
------------------------------------------------------------------------------*/
.header__navs--clone {
	display: none;
	@media (min-width: @mq-l) and (max-width: (@mq-xl - 0.0625em)) {
		display: block;
	}
}

.header__navs--main {
	@media (max-width: (@mq-xl - 0.0625em)) {
		background: @color-primary;
		color: #fff;
		position: fixed;
		width: 100vw;
		height: 100vh;
		height: -webkit-fill-available;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1080;
		display: flex;
		flex-direction: column;
		transform: translateX(100vw);
		transition: transform .35s @ease;
		.main-nav {
			padding: 1rem 1.5rem 2rem;
			overflow-y: auto;
			flex: 1;
		}
		.main-nav-items {
			display: block;
		}
		.main-nav-item {
			margin: 0 0 1rem;
		}
		.main-nav-link {
			color: currentColor;
			font-size: 1.4375rem;
			line-height: 1.4;
		}
	}
	@media (min-width: @mq-m) and (max-width: (@mq-xl - 0.0625em)) {
		flex-direction: row;
		flex-wrap: wrap;
		.main-nav {
			padding-top: 0;
			padding-left: 2.5rem;
		}
		.main-nav-item {
			margin: 0 0 1.75rem;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.main-nav-link {
			font-size: 2rem;
		}
	}
}
.header__navs-top {
	display: none;
	@media (max-width: (@mq-xl - 0.0625em)) {
		height: @header-height--mobile;
		display: flex;
		align-items: center;
		padding: 0 1.5rem;
		width: 100%;
	}
	@media (min-width: @mq-l) and (max-width: (@mq-xl - 0.0625em)) {
		padding: 0 2.5rem;
		height: 6.5rem;
	}
}
.header__navs-close {
	margin: 0 -10px 0 auto;
	width: 44px;
	height: 44px;
}

.header__navs-secondary {
	margin-top: auto;
	min-height: 20.5rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	flex: 1;
	overflow: hidden;
	.header__bar-nav {
		display: block;
		width: 100%;
		border-top: 1px solid fade(#fff, 16%);
		padding: 2rem 1.5rem;
	}
	.header__bar-nav-item {
		border: 0;
	}
	.header__bar-nav-link {
		font-size: 0.875rem;
		padding-left: 0;
		padding-right: 0;
		&:hover,
		&:focus {
			background: 0;
		}
		&.is-active {
			background: 0;
			color: @color-secondary;
		}
	}
	.header__bar-nav--default {
		padding-top: 2rem;
		padding-bottom: 2rem;
		overflow-y: auto;
		flex: 1;
	}
	.header__bar-nav--ctas {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		display: flex;
		justify-content: space-between;
	}
	@media (min-width: @mq-xl) {
		display: none;
	}
	@media (min-width: @mq-m) and (max-width: (@mq-xl - 0.0625em)) {
		flex-direction: row;
		align-items: flex-start;
		margin-top: -0.5rem;
		padding-right: 2.5rem;
		.header__bar-nav {
			flex: 1;
			border: 0;
			padding: 0;
			display: block;
		}
		.header__bar-nav-link {
			padding: 1rem 0;
		}
	}
}
.site-nav-is-open {
	@media (max-width: (@mq-xl - 0.0625em)) {
		overflow: hidden;
		.header__navs {
			transform: none;
		}
	}
}


//On scroll
.headroom--not-top {
	.header {
		box-shadow: 0 14px 28px rgba(0,0,0,0.025), 0 10px 10px rgba(0,0,0,0.025);
		border-bottom-color: #fff;
		@media (min-width: @mq-l) {
			height: @header-height--condensed;
		}
	}
	.header__logo {
		img {
			width: 108px;
		}
	}
	.header__bar {
		display: none;
	}
	.main-nav-link {
		@media (min-width: @mq-xxl) {
			font-size: 1.125rem;
		}
	}
}
.headroom--not-top.headroom--unpinned {
	.header {
		transform: translateY(-100%);
		.cart-added {
			opacity: 0;
			pointer-events: none;
		}
	}
}
@import url("//fast.fonts.net/t/1.css?apiType=css&projectid=99a5c0b8-380f-4e12-854c-f73915913bc2");

@font-face {
	font-family: "DIN Next™ W01";
	src: url("/fonts/6b4d4830-9c7d-43d2-b5d3-c73f739561b9.woff2") format("woff2"), url("/fonts/ccf25ada-6d2a-4133-95dc-deb039e22999.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "DIN Next™ W01";
	src: url("/fonts/e8258209-d292-4480-9fe3-b30dfa63ea97.woff2") format("woff2"), url("/fonts/c3cbbafb-1878-424d-b2f5-b176d0f9b370.woff") format("woff");
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "DIN Next™ W01";
	src: url("/fonts/afabdfcf-432c-49b4-a496-0e5051e87fa9.woff2") format("woff2"), url("/fonts/611ea865-db7e-4c05-94b8-bd1675c90576.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "DIN Next™ W01";
	src: url("/fonts/03008527-67bd-478e-98e3-3b56dd9a6520.woff2") format("woff2"), url("/fonts/557f817d-9c25-4a23-baaf-a3cf84a7cd7c.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

//
@font-face {
	font-family: 'Stag Web';
	src: url('/fonts/Stag-Book-Web.woff2') format('woff2'),
		url('/fonts/Stag-Book-Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Stag Web';
	src: url('/fonts/Stag-Medium-Web.woff2') format('woff2'),
		url('/fonts/Stag-Medium-Web.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}
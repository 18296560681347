.template--brands {
	.header {
		border-bottom: 0;
	}
	// .sizes-nav {
	// 	@media (min-width: @mq-l) {
	// 		display: none;
	// 	}
	// }
	// .sizes-nav__link {
	// 	justify-content: center;
	// 	padding: 2rem;
	// 	.icon {
	// 		width: auto;
	// 		height: auto;
	// 		margin: auto;
	// 	}
	// }
}


/*------------------------------------------------------------------------------
	/Hero
------------------------------------------------------------------------------*/
.brands-hero {
	position: relative;
	overflow: hidden;
	padding: 3rem 0 0.5rem;
	margin: -1px 0 0;
	background: @color-primary--light;
	@media (min-width: @mq-m) {
		padding-top: 4rem;
	}
	@media (min-width: @mq-xl) {
		padding: 7.125rem 0 2.5rem;
		color: @color-primary;
	}
}
.brands-hero__layout {
	margin: 0;
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 2;
	@media (min-width: @mq-xl) {
		align-items: center;
	}
}
.brands-hero__header {
	margin: 0 0 0.875rem;
	color: @color-primary;
	a {
		color: currentColor;
	}
	+ .brands-nav {
		@media (min-width: @mq-xl) {
			margin-top: 4.875rem;
		}
	}
}




/*------------------------------------------------------------------------------
	/Nav
------------------------------------------------------------------------------*/
.brands-nav {
	--brands-nav-height: 7.5rem;
	--brands-nav-arrow-width: 2.5rem;
	--brands-nav-gap: 0.5rem;
	--brands-nav-border-color: #D6E5F3;
	
	z-index: 2;
	position: relative;
	display: flex;
	align-items: center;
	width: 100vw;
	min-height: var(--brands-nav-height);
	margin: 0 -1.5rem;
	padding: 0 1.5rem;
	overflow-x: auto;
	list-style: none;
	gap: var(--brands-nav-gap);
	scroll-snap-type: x mandatory;
	
	&:after {
		content: 'flickity';
		display: none;
		
		@media (max-width: (@mq-xl - 0.0625em)) {
			content: '';
		}
	}
	
	.flickity-viewport {
		width: 100%;
		background: #fff;
	}
	
	@media (min-width: @mq-l) {
		margin: 0 -3.5rem;
		padding-left: 3.5rem;
	}
	@media (min-width: @mq-xl) {
		width: 100%;
		margin: 0;
		padding: 0;
		scroll-snap-type: none;
		height: var(--brands-nav-height);
		--brands-nav-gap: 0;
		
		&.flickity-enabled {
			padding-inline: var(--brands-nav-arrow-width);
		}
	}
	// &:after {
	// 	content: "";
	// 	min-width: 0.5rem;
	// 	height: 1px;
	// 	display: block;
	// }
}

.brands-nav .flickity-prev-next-button {
	width: var(--brands-nav-arrow-width);
	height: var(--brands-nav-height);
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	border-radius: 0;
	margin: 0;
	position: absolute;
	top: 0;
	bottom: 0;
	opacity: 1;
	z-index: 10;
	transition: .15s;
	border-left: 1px solid var(--brands-nav-border-color);
	border-radius: 0 @border-radius @border-radius 0;
	
	&:before {
		content: "";
		display: block;
		width: 1rem;
		height: 1rem;
		background: url('/images/icons/icon--chevron.svg') no-repeat 50% 50% / contain;
	}
	
	svg {
		display: none;
	}
	
	&.previous {
		left: 0;
	}
	&.next {
		right: 0;
		margin: 0;
	}
	&[disabled] {
		&:before {
			opacity: .16;
		}
	}
	
	&:is(:hover, :focus) {
		outline: 0;
		background: tint(@color-primary, 98%);
	}
	&:focus-visible {
		border: 2px solid @color-primary;
	}
}

.brands-nav__item {
	display: flex;
	position: relative;
	flex: 0 0 10rem;
	flex-direction: column;
	width: 10rem;
	height: var(--brands-nav-height);
	margin: 0;
	padding: 0;
	scroll-snap-align: center;
	@media (min-width: @mq-xl) {
		min-width: 0;
		padding: 0;
		.flickity-enabled & {
			&:not(:first-child) {
				border-left: 1px solid var(--brands-nav-border-color);
			}
		}
	}
	@media (min-width: @mq-xxl) {
		flex: 0 0 10.5rem;
		width: 10.5rem;
	}
}
.brands-nav__link {
	flex: 1;
	width: 100%;
	height: 100%;
	background: #fff;
	text-align: center;
	padding: 1rem 1.5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 2px solid transparent;
	border-radius: @border-radius;
	.icon {
		max-height: 3.5rem;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0;
		color: transparent;
		img {
			max-height: 100%;
		}
	}
	&:hover,
	&:focus {
		outline: 0;
		@media (min-width: @mq-xxl) {
			border-color: currentColor;
		}
	}
	&.is-active {
		border-color: @color-primary;
	}
	@media (min-width: @mq-xl) {
		border-radius: 0;
	}
}



/*------------------------------------------------------------------------------
	/Nav (mobile)
------------------------------------------------------------------------------*/
.brands-nav-mobile {
	border: 1px solid tint(@color-body, 84%);
	padding: 1px;
	border-radius: @border-radius;
	margin-top: 4.5rem;
	@media (min-width: @mq-l) {
		display: none;
	}
	@media (min-width: @mq-xl) {
		top: 8rem;
	}
}
.brands-nav-mobile__items {
	display: flex;
	flex-wrap: wrap;
	gap: 1px;
	background: tint(@color-body, 84%);
}
.brands-nav-mobile__item {
	width: calc(50% - 0.03125rem);
}
.brands-nav-mobile__link {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	padding: 1.5rem;
	height: 7.5rem;
	position: relative;
	img {
		max-height: 3.5rem;
		flex-shrink: 0;
		@media (min-width: @mq-l) and (max-width: (@mq-xl - 0.0625em)) {
			max-height: 2.375rem;
		}
	}
	&:after {
		content: "";
		position: absolute;
		top: -1px;
		right: -1px;
		bottom: -1px;
		left: -1px;
		border: 2px solid @color-links;
		opacity: 0;
		transition: opacity .15s;
	}
	&:hover,
	&:focus,
	&.is-active {
		&:after {
			opacity: 1;
		}
	}
	
	@media (min-width: @mq-l) and (max-width: (@mq-xl - 0.0625em)) {
		height: 3.5rem;
	}
}


/*------------------------------------------------------------------------------
	/Body
------------------------------------------------------------------------------*/
.brands-body {
	@media (max-width: (@mq-m - 0.0625em)) {
		padding-top: 1.5rem;
	}
}

.brands-body__layout {
	> * {
		transition: opacity .2s;
	}
	.loading {
		background: 0;
		position: absolute;
		left: 0;
		right: 0;
	}
	.loading ~ * {
		opacity: 0;
	}
}



.brands-body__content {
	scroll-margin-top: @header-height--mobile;
}
.brand-content__header {
	padding: 2rem 0;
	@media (max-width: (@mq-xl - 0.0625em)) {
		display: none;
	}
}
.brand-content__logo {
	min-width: 230px;
}

.brand-block {
	margin: 3rem auto 0;
	width: 100%;
	max-width: 40rem;
	@media (min-width: @mq-l) {
		margin: 4.75rem 0 0;
		max-width: 45rem;
	}
	p {
		max-width: 100%;
	}
	&:first-child {
		margin-top: 0;
	}
}
.brand-block__logo {
	margin: 1.125rem 0 2rem;
}
.brand-block__content {
	h3 {
		font-family: inherit;
		font-size: 1.0625rem;
		line-height: 1.4;
		.weight-medium;
		text-transform: uppercase;
		margin: 1em 0;
	}
	img {
		display: block;
		margin-top: 2em;
		margin-bottom: 2em;
	}
	> h3:first-child,
	> figure:first-child img {
		margin-top: 0;
	}
}


/*------------------------------------------------------------------------------
	/Sidebar
------------------------------------------------------------------------------*/
.brands-body__sidebar {
	@media (max-width: (@mq-m - 0.0625em)) {
		margin-top: 2rem;
	}
	@media (min-width: @mq-l) and (max-width: (@mq-xxl - 0.0625em)) {
		padding-right: 0;
	}
}

.brand-subhero {
	--subhero-spacing-top: 3.5rem;
	--subhero-spacing-bottom: 3.5rem;
	
	display: none;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	margin-bottom: 3rem;
	position: sticky;
	top: var(--subhero-spacing-top);
	bottom: var(--subhero-spacing-bottom);
	aspect-ratio: 4 / 3;
	border-radius: @border-radius;
	overflow: hidden;
	
	@media (min-width: @mq-l) {
		display: grid;
		aspect-ratio: auto;
		height: calc(100vh - var(--subhero-spacing-top) - var(--subhero-spacing-bottom));
		margin-bottom: 0;
	}
}

.brand-subhero__inner {
	grid-column: 1;
	grid-row: 1;
	position: relative;
	z-index: 2;
	padding: 2rem;
	align-self: end;
	color: #fff;
	@media (min-width: @mq-xl) {
		padding: 3rem;
	}
}

.brand-subhero__logo {
	display: block;
	max-width: 14.375rem;
	max-height: 3.5rem;
	@media (min-width: @mq-l) and (max-width: (@mq-xl - 0.0625em)) {
		max-width: 12.5rem;
	}
	@media (min-width: @mq-xl) {
		max-height: 6rem;
	}
}

.brand-subhero__cta {
	margin-top: 1rem;
}

.brand-subhero__image {
	position: relative;
	overflow: hidden;
	grid-column: 1;
	grid-row: 1;
	background: @color-primary--light;
	&:before {
		content: "";
		inset: 0;
		position: absolute;
		z-index: 0;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
		@media (min-width: @mq-l) {
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60.88%, rgba(0, 0, 0, 0.4) 100%);
		}
	}
	img {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}
}

.brand-subhero--no-image {
	.brand-subhero__inner {
		color: currentColor;
	}
	.brand-subhero__image:before {
		display: none;
	}
}
.product {
	margin: 1.25rem 0;
	@media (min-width: @mq-m) {
		margin: 2rem 0 0;
	}
	@media (min-width: @mq-l) {
		margin: 5rem 0 0;
	
	}
}

.product__layout,
.product-details__layout {
	@media (min-width: @mq-m) {
		.cancel-grid-row;
	}
}

/*------------------------------------------------------------------------------
    /Gallery
------------------------------------------------------------------------------*/
.product__media {
	// grid-column: 1 / 8;
	position: relative;
	&.has-gallery {
		margin-bottom: 2.5rem;
		@media (min-width: @mq-xl) {
			margin-bottom: 0;
		}
	}
	@media (min-width: @mq-xl) {
		margin: 0;
	}
}

.product__gallery {
	position: relative;
	@media (min-width: @mq-m) {
		.aspect-ratio--4x3;
	}
}

.product__gallery-stamp {
	position: absolute;
	z-index: 2;
	width: 5rem;
	height: 5rem;
	top: 1rem;
	right: 1rem;
}

.product__gallery-slides {
	width: 100%;
	height: 75vw;
	// background-color: @color-divider;
	@media (min-width: @mq-m) {
		height: auto;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	.flickity-slider {
		position: relative;
	}
}
.product__gallery-slide {
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

.product__gallery-nav {
	display: flex;
	// align-items: center;
	align-items: flex-end;
	justify-content: space-between;
	margin: 1rem 0 0;
	@media (min-width: @mq-m) {
		margin: 0;
		position: absolute;
		left: 1rem;
		right: 1rem;
		bottom: 1rem;
	}
}

.product__gallery-thumbs {
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	order: -1;
	max-width: 9rem;
	@media (min-width: @mq-s) {
		max-width: 18rem;
	}
	@media (min-width: @mq-m) {
		max-width: 80%;
	}
}
.product__gallery-thumb {
	display: inline-block;
	width: 3rem;
	margin-left: -1px;
	position: relative;
	outline: 0;
	cursor: pointer;
	background-color: #fff;
	@media (min-width: @mq-m) {
		width: 5rem;
	}
	
	&:before {
		content: "";
		float: left;
		padding-bottom: 100%;
	}
	&:after {
		content: "";
		position: absolute;
		z-index: 2;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: 0;
		border: 3px solid @color-primary;
		opacity: 0;
		transition: .2s;
	}
	.image-wrap {
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	
	&.is-contain {
		.image-wrap {
			border: 1px solid @color-divider;
		}
	}
	
	&:hover,
	&:focus,
	&.is-selected {
		&:after {
			opacity: 1;
		}
	}
}

.product__gallery-nav__lateral {
	display: flex;
	@media (min-width: @mq-m) {
		margin-bottom: 1rem;
	}
}
.product__gallery-nav__lateral-button {
	color: @color-primary;
	background: #fff;
	width: 4rem;
	height: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid @color-divider;
	transition: .2s;
	outline: 0;
	cursor: pointer;
	& + & {
		margin-left: -1px;
		@media (min-width: @mq-m) {
			margin-left: 0.5rem;
		}
	}
	&.slide-prev {
		border-radius: 40px 0 0 40px;
		svg {
			transform: scaleX(-1);
			margin-right: -8px;
		}
	}
	&.slide-next {
		border-radius: 0 40px 40px 0;
		svg {
			margin-left: -8px;
		}
	}
	&:hover,
	&:focus {
		background: @color-primary;
		border-color: @color-primary;
		color: #fff;
	}
	&.is-disabled {
		pointer-events: none;
		svg {
			opacity: 0.5;
		}
	}
	@media (min-width: @mq-m) {
		width: 3rem;
		border: 0;
		&.slide-prev,
		&.slide-next {
			border-radius: 50%;
			svg {
				margin: 0;
			}
		}
	}
}


/*------------------------------------------------------------------------------
    /Meta
------------------------------------------------------------------------------*/
.product__meta {
	grid-column: 8 / 12;
	display: flex;
	flex-direction: column;
}

.product__header {
}
.product__header--default {
	margin: 0 0 4.25rem;
	display: none;
	@media (min-width: @mq-xl) {
		display: block;
	}
}
.product__header--mobile {
	margin: 0 0 0.75rem;
	@media (min-width: @mq-xl) {
		display: none;
	}
}

.product__brand {
	margin: 0.5em 0 0;
}

.product__desc {
	margin: 0 0 2.25rem;
	@media (min-width: @mq-xl) {
		margin: 0 0 4rem;
	}
}

.product__add-to-cart {
	margin: 0.5rem 0 0;
	width: 100%;
	height: 4rem;
	font-size: 1.4375rem;
	@media (min-width: @mq-m) {
		width: auto;
		margin: 1rem 0 0;
	}
}

.product__options {
	padding: 1rem 0 0;
}
.product__sizes {
	display: flex;
}
.product__size {
	position: relative;
	height: 48px;
	margin: 0 4px -1px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	border-bottom: 0;
	
	input[type="radio"] {
		opacity: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		cursor: pointer;
		~ i {
			width: 100%;
			height:  7px;
			position: absolute;
			background: url('/images/size-choice-indicator-alt.svg') no-repeat 50% 100%/contain;
			right: 0;
			bottom: 0;
			left: 0;
			opacity: 0;
			transition: 0.15s;
		}
	}
	input[type="radio"]:checked {
		~ .product__size-label {
			color: #fff;
			background: @color-primary;
		}
		~ i {
			opacity: 1;
		}
	}
	
	&:hover,
	&:focus {
		.product__size-label {
			color: #fff;
			background: @color-primary;
		}
	}
	
	&.is-oos {
		.product__size-label {
			color: @color-grey;
			background: fade(@color-grey, 16%);
		}
		input[type="radio"] ~ i {
			background-image: url('/images/size-choice-indicator-alt--oos.svg')
		}
		input[type="radio"]:checked {
			~ .product__size-label {
				background: @color-grey;
				color: #fff;
			}
		}
	}
}
.product__size-label {
	padding: 5px 12px;
	border-radius: 1rem;
	color: @color-primary;
	background: @color-primary--light;
	border: 1px solid transparent;
	font-size: 0.75rem;
	.weight-medium;
	text-transform: uppercase;
	white-space: nowrap;
	transition: .15s;
	&.short {
		width: 24px;
		height: 24px;
		padding: 0;
		font-size: 0.75rem;
		display: flex;
		align-items: center;
		justify-content: center;
		@media (min-width: @mq-m) {
			display: none;
		}
	}
	&.full {
		display: none;
		@media (min-width: @mq-m) {
			display: block;
		}
	}
}

.product__size-output {
	border-top: 1px solid @color-primary;
	&.is-oos {
		color: @color-grey;
		border-top-color: @color-grey;
	}
}
.product__size-info {
	padding: 2rem 0;
}
.product__size-info-text {
	display: flex;
	align-items: flex-end;
}
.product__size-info-price {
	font-size: 2.5rem;
	.weight-bold;
	line-height: 1;
	margin: 0 1rem 0 0;
}
.product__size-info-original-price {
	font-size: 1.4375rem;
	.weight-medium;
	color: @color-grey;
	text-decoration: line-through;
	margin: 0 1rem 0 0;
}
.product__size-info-label {
	// align-self: center;
	display: flex;
	flex-direction: column;
	.size {
		.weight-bold;
		@media (min-width: @mq-m) {
			display: none;
		}
	}
	.dimensions {
		font-size: 0.8125rem;
	}
}

.product__special-notes {
	display: flex;
	flex-wrap: wrap;
}
.product__special-note {
	font-size: 1em;
	.weight-medium;
	text-transform: uppercase;
	color: @color-primary;
	// margin: 0.25em 0.5em 0.25em 0;
	margin: 0 0.5em 0 0;
	@media (min-width: @mq-m) {
		font-size: 0.9375rem;
	}
	& + & {
		&:before {
			content: "";
			display: inline-block;
			vertical-align: top;
			width: 4px;
			height: 4px;
			background: @color-grey;
			border-radius: 50%;
			margin: 0.5em 0.25em 0 0;
		}
	}
	&.red {
		color: @color-red;
	}
	&.green {
		color: #30a28b;
	}
	&.black {
		color: @color-body;
	}
}


/*------------------------------------------------------------------------------
	/Payment methods
------------------------------------------------------------------------------*/
.payment-methods {
	position: relative;
	&.is-out-of-stock {
		.payment-method {
			color: @color-grey;
		}
	}
}
.payment-method {
	border-radius: @border-radius;
	background: fade(@color-grey, 10%);
	padding: 1rem 7rem 1rem 1rem;
	display: flex;
	align-items: center;
	& + & {
		margin-top: 2px;
	}
}
.payment-method__label {
	padding: 0 0.5rem;
	font-size: 0.9375rem;
	// display: flex;
	// align-items: center;
}
.payment-method__logo {
	margin: 0 0 0 0.25rem;
	display: inline-block;
	vertical-align: middle;
}
.payment-method__show-all {
	position: absolute;
	top: 1rem;
	right: 1rem;
	padding: 3px 12px;
    border-radius: 1rem;
    color: #fff;
    background: @color-primary;
    border: 1px solid transparent;
    font-size: 0.75rem;
    .weight-medium;
    text-transform: uppercase;
    white-space: nowrap;
	transition: .15s;
	&:hover,
	&:focus {
		background: @color-primary--dark;
	}
	&.is-disabled,
	&[disabled] {
		background: @color-grey;
	}
	&[disabled]{
		cursor: default;
	}
}

//payment methods modal
.payment-methods-modal {
	width: 100%;
	max-width: 53rem;
	padding: 1.5rem;
	@media (min-width: @mq-m) {
		padding: 4rem;
	}
}
.payment-methods-full {
	border: 1px solid @color-divider;
	border-radius: @border-radius;
}
.payment-method-full {
	padding: 2rem;

	& + & {
		border-top: 1px solid @color-divider;
	}
	.title {
		font-family: @font-body;
		font-size: 1em;
		.weight-medium;
		margin: 0 0 0.5rem;
		text-transform: uppercase;
	}
	.logo {
		height: 24px;
	}
	.cards {
		display: flex;
		align-items: center;
		margin-top: 2em;
		li + li {
			margin-left: 4px;
		}
	}
	@media (min-width: @mq-m) {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		.text {
			flex: 0 0 70%;
			padding-right: 2em;
		}
	}
}


/*------------------------------------------------------------------------------
    /Mobile sticky bar
------------------------------------------------------------------------------*/
.product-bar {
	position: sticky;
	top: 0;
	z-index: 2000;
	height: 0;
	&.is-sticky {
		.product-bar__layout {
			display: flex;
		}
	}
	@media (min-width: @mq-xl) {
		display: none;
	}
}
.headroom--not-top.headroom--pinned {
	.product-bar {
		display: none;
	}
}
.product-bar__variant {
	&.is-out-of-stock {
		color: @color-grey;
	}
}
.product-bar__layout {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 80px;
	padding: 1rem @half-gutter-width;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid @color-divider;
	background: #fff;
	display: none;
	.button {
		margin: 0 0 0 auto;
		width: 3.5rem;
		height: 3rem;
	}
}
.product-bar__text {
	padding-right: 1rem;
}
.product-bar__title {
	font-family: @font-headings;
	font-size: 1rem;
	line-height: 1.75;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: calc(100vw - 10rem);
}
.product-bar__meta {
	display: flex;
	align-items: baseline;
}
.product-bar__price {
	font-size: 1.4375rem;
	line-height: 1.043;
	.weight-bold;
	margin-right: 4px;
}
.product-bar__size {
	font-size: 0.8125rem;
	color: @color-grey;
}

/*------------------------------------------------------------------------------
    /Details
------------------------------------------------------------------------------*/
.product-details {
	@media (max-width: (@mq-xl - 0.0625em)) {
		padding-top: 0;
		margin-top: 2.75rem;
	}
}
.product-details__layout {
	@media (min-width: @mq-xl) {
		.cancel-grid-row;
	}
}
.product-details__desc {
	margin: 0 0 2rem;
	@media (min-width: @mq-xl) {
		margin: 0;
	}
}
.product-details__indicators {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.comfort-wrap {
	margin: 0 0 1.625rem;
}
.comfort-wrap__heading {
	font-family: inherit;
	font-size: 0.9375rem;
	color: @color-primary;
	text-transform: uppercase;
	.weight-medium;
	margin: 0 0 1.5em;
	@media (min-width: @mq-m) {
		display: none;
	}
}
.comfort {
	background: tint(@color-primary, 76%);
	border-radius: 2.5rem;
	padding: 0.5rem;
	display: flex;
	align-items: center;
	+ .product-badges {
		margin-top: 1rem;
	}
}
.comfort__heading {
	font-size: 0.9375rem;
	.weight-medium;
	text-transform: uppercase;
	color: @color-primary;
	margin: 0 0.75rem;
	display: none;
	@media (min-width: @mq-m) {
		display: block;
	}
}
.comfort__grades {
	display: flex;
	align-items: center;
}
.comfort__grade {
	border-radius: 50%;
	& + & {
		margin: 0 4px;
	}
	i {
		display: block;
		width: 2rem;
		height: 2rem;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: contain;
		border-radius: 50%;
	}
	&.is-active {
		border: 6px solid tint(@color-primary, 76%);
		margin: -20px 0;
		i {
			width: 64px;
			height: 64px;
			border: 4px solid #fff;
		}
	}
}
.comfort__grade-label {
	background: #fff;
	color: @color-primary--dark;
	border-radius: 40px;
	min-height: 2rem;
	padding: 0.25rem 1rem;
	margin: 0 0 0 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
}


.product-badges {
	display: flex;
	flex-wrap: wrap;
}
.product-badge {
	display: flex;
	align-items: center;
	color: @color-primary;
	margin: 1rem 0;
	padding-right: 0.5em;
	flex: 1;
}
.product-badge__img {
	margin: 0 0.75rem 0 0;
}
.product-badge__label {
	max-width: 95px;
	max-width: 12ch;
	line-height: 1.0625;
}

/*------------------------------------------------------------------------------
    /Related products
------------------------------------------------------------------------------*/
.related {
	position: relative;
	border-top: 1px solid @color-divider;
	overflow: hidden;
	padding-bottom: 5rem;
	@media (min-width: @mq-m) {
		padding-bottom: 0;
	}
	@media (min-width: @mq-xl) {
		border: 0;
	}
}
.related__layout {
	@media (min-width: @mq-xl) {
		border-top: 1px solid @color-divider;
	}
}
.related__header {
	margin: 0 0 3rem;
	display: flex;
	.button {
		margin: 0 0 0 auto;
		@media (max-width: (@mq-m - 0.0625em)) {
			.button--primary;
			margin: 0;
			position: absolute;
			bottom: 4rem;
			left: 1.5rem;
			padding: 0.75rem 1.5rem;
			font-size: 0.75rem;
			.weight-medium;
			text-transform: uppercase;
			.button-label {
				border: 0;
			}
			svg {
				display: none;
			}
		}
	}
}
.related__products {
	width: 100vw;
	display: flex;
	scroll-snap-type: x mandatory;
	overflow-x: auto;
	padding: 0 0 1rem 1.5rem;
	margin: 0 -1.5rem -1rem;
	
	@media (min-width: @mq-l) {
		padding-left: 3.5rem;
		margin-left: -3.5rem;
		margin-right: -3.5rem;
	}
	@media (min-width: @mq-xl) {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: 1fr;
		gap: 3rem;
		overflow: visible;
		width: 100%;
		padding: 0;
		margin: 0;
	}
	.c-prod-card {
		// min-width: 85vw;
		min-width: calc(100vw - 3rem);
		scroll-snap-align: center;
		padding: 0 1.5rem 0 0;
		@media (min-width: @mq-m) {
			// min-width: 44vw;
			min-width: calc(50vw - 3rem);
		}
		@media (min-width: @mq-xl) {
			width: 100%;
			min-width: 0;
			padding: 0;
		}
	}
}
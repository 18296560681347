.c-prod-card {
	// flex: 1;
	display: flex;
	flex-direction: column;
}
.c-prod-card__layout {
	display: flex;
	flex-direction: column;
}



.c-prod-card__link {
	color: currentColor;
	&:hover,
	&:focus {
		color: @color-primary;
		outline: 0;
	}
}
.c-prod-card__media {
	margin: 0 0 1.25rem;
	.img-resizer {
		.aspect-ratio--4x3;
	}
}
.c-prod-card__stamp {
	position: absolute;
	width: 88px;
	height: 88px;
	top: 6px; 
	right: 6px;
	transform: rotate(16deg);
	
	&.--no-rotation {
		transform: none!important;
	}
}
.c-prod-card__stamp--left {
	width: 88px;
	height: 88px;
	top: 0;
	right: auto;
	left: 6px;
	transform: none;
}
.c-prod-card__text {
	margin: 0 0 1rem;
	padding-right: 3rem;
}
.c-prod-card__brand {
	font-size: 0.85rem;
	.weight-regular;
	line-height: 1.33;
	text-transform: uppercase;
	margin: 0.5em 0 0;
}
.c-prod-card__price-range {
	font-size: 0.9375rem;
	.weight-regular;
	line-height: 1.0666;
	margin: 0.5em 0 0;
}



.c-prod-card__options {
	padding: 1rem 0 0;
	margin-top: auto;
	color: @color-primary;
}
.c-prod-card__sizes {
	display: flex;
	border-bottom: 1px solid tint(@color-primary, 76%);
}
.c-prod-card__size {
	position: relative;
	width: 28px;
	height: 40px;
	margin: 0 0 -1px;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	border-bottom: 1px solid tint(@color-primary, 76%);
	
	input[type="radio"] {
		opacity: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		cursor: pointer;
		~ i {
			width: 100%;
			height:  7px;
			position: absolute;
			background: url('/images/size-choice-indicator.svg') no-repeat 50% 100%/contain;
			right: 0;
			bottom: -1px;
			left: 0;
			opacity: 0;
			transition: 0.15s;
		}
	}
	input[type="radio"]:checked {
		+ .c-prod-card__size-label {
			background: @color-primary;
			color: #fff;
		}
		~ i {
			opacity: 1;
		}
	}
	
	&:hover,
	&:focus {
		color: #fff;
		.c-prod-card__size-label {
			background: @color-primary;
		}
	}
	
	&.is-oos {
		.c-prod-card__size-label {
			color: @color-grey;
			background: fade(@color-grey, 16%);
		}
		input[type="radio"]:checked {
			+ .c-prod-card__size-label {
				background: @color-grey;
				color: #fff;
			}
		}
	}
}
.c-prod-card__size-label {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	.small-caps;
	line-height: 1;
	color: currentColor;
	background: @color-primary--light;
	transition: .15s;
}


.c-prod-card__size-output {
	position: relative;
	display: flex;
	justify-content: space-between;
	&.is-oos {
		color: @color-grey;
	}
}
.c-prod-card__size-info {
	padding: 1rem 0;
	flex: 1;
}
.c-prod-card__size-info-text {
	display: flex;
	align-items: baseline;
}
.c-prod-card__size-info-price {
	font-size: 1.4em;
	.weight-bold;
	margin-right: 0.25em;

	&.--sale{
		color: @color-red;
	}
}
.c-prod-card__size-info-original-price {
	color: @color-grey;
	text-decoration: line-through;
	.weight-medium;
	margin-right: 0.2em;
}
.c-prod-card__add-to-cart {
	width: 44px;
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-shrink: 0;
	margin: 8px 0 0 auto;
	&:hover,
	&:focus {
		color: @color-tertiary;
	}
	
	&.adding {
		&:extend(.loading);
	
		display: flex;
		align-self: center;
		width: 44px;
		height: 44px;
		margin: 0;
		background: 0;
		
		&:before {
			&:extend(.loading:before);
		}
	}
}
.c-prod-card__status {
	padding: 1rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	.small-caps;
	
	&.out-of-stock {
		// color: @color-red;
	}
}


.c-prod-card__tags-wrap {
	border-top: 1px solid tint(@color-primary, 76%);
	@media (min-width: @mq-s) {
		min-height: 40px;
	}
}
.c-prod-card__tags {
	.list-reset;
	padding: 13px 0 0;
	color: @color-primary;
	display: flex;
	flex-wrap: wrap;
}
.c-prod-card__tag {
	.small-caps;
	margin: 0 0.5rem 0.5rem 0;
	& + & {
		&:before {
			content: "";
			display: inline-block;
			vertical-align: top;
			width: 4px;
			height: 4px;
			background: @color-grey;
			border-radius: 50%;
			margin: 5px 0.5rem 0 0;
		}
	}
	&.red {
		color: @color-red;
	}
	&.green {
		color: #30a28b;
	}
	&.black {
		color: @color-body;
	}
}



/*-----------------------------------------------------------------------------------------
	/Content placeholder
-----------------------------------------------------------------------------------------*/
.content-placeholder {
	background: tint(@color-body, 95%);
	overflow: hidden;
	pointer-events: none;
	transition: .2s;
	&:before {
		content: "";
		animation: content-placeholder-shimmer 1s linear infinite;
		background: linear-gradient(90deg, fade(@color-body, 0) 0%, tint(@color-body, 85%) 40%, tint(@color-body, 85%) 60%, fade(@color-body, 0) 100%);
		display: block;
		transform: translateX(0);
		width: 200%;
		height: 100%;
	}
}
.content-placeholder--media {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.content-placeholder--text {
	border-radius: @border-radius;
}

@keyframes content-placeholder-shimmer {
	0% {
		transform: translateX(-100%)
	}

	to {
		transform: translateX(50%)
	}
}
//out: false

// Put this here as npm watch was telling me that this variables weren't found upon compiling
@header-bar-height: 2rem;

@header-height--mobile: 4.5rem;
@header-height--tablet: 6.5rem;
@header-height: 9.5rem;
@header-height--condensed: 4.5rem;

/*-----------------------------------------------------------------------------------------
	/Less imports
-----------------------------------------------------------------------------------------*/
@import "plugin--flickity";
@import "plugin--fancybox";
@import "plugin--snazzy-info-window";

@import "_reset";
@import "_fonts";
@import "_lib";

@import "base--global";
@import "base--typography";

@import "layout--grid";
@import "layout--header";
@import "layout--footer";
@import "layout--forms";

@import "component--button";
@import "component--card";
@import "component--product-card";
@import "component--bestsellers";
@import "component--hero";
@import "component--review";
@import "component--pagination";
@import "component--cms-block";
@import "component--cart-panel";
@import "component--side-panel";
@import "component--side-matches";

@import "template--home";
@import "template--product-list";
@import "template--product-detail";
@import "template--sizes";
@import "template--brands";
@import "template--stores";
@import "template--store-detail";
@import "template--blog";
@import "template--blog-detail";
@import "template--contact";
@import "template--student-beds";
@import "template--search-results";
@import "template--common";
@import "template--404";


// @import "_ie";

.sf-toolbar{ display: none !important; }

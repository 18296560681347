.c-best {
	overflow: hidden;
}
.c-best__layout {
	border-top: 1px solid @color-divider;
}

.c-best__header {
	margin: 0 0 2rem;
	@media (min-width: @mq-m) {
		margin: 0 0 3rem;
	}
	@media (min-width: @mq-xxl) {
		display: grid;
		grid-template-columns: 3fr 9fr;
		grid-template-rows: 1fr;
		gap: 1rem;
	}
}

.c-best__tabs {
	margin: 0 -1.5rem;
	padding: 0 0 0 1.5rem;
}

.c-best__panel {
	outline: 0;
}

.c-best__products {
	width: 100vw;
	display: flex;
	scroll-snap-type: x mandatory;
	overflow-x: auto;
	padding: 0 0 1rem 1.5rem;
	margin: 0 -1.5rem -1rem;
	
	@media (min-width: @mq-l) {
		padding-left: 3.5rem;
		margin-left: -3.5rem;
		margin-right: -3.5rem;
	}
	@media (min-width: @mq-xl) {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: 1fr;
		gap: 3rem;
		overflow: visible;
		width: 100%;
		padding: 0;
		margin: 0;
	}
	.c-prod-card {
		// min-width: 85vw;
		min-width: calc(100vw - 3rem);
		scroll-snap-align: center;
		padding: 0 1.5rem 0 0;
		@media (min-width: @mq-s) {
			min-width: calc(70vw - 3rem);
		}
		@media (min-width: @mq-m) {
			min-width: calc(50vw - 3rem);
		}
		@media (min-width: @mq-xl) {
			width: 100%;
			min-width: 0;
			padding: 0;
		}
	}
}

.c-best__cta {
	margin: 2rem 0 0;
	@media (min-width: @mq-xl) {
		margin: 3.5rem 0 0;
		text-align: center;
	}
}


// TODO: treat as component?
.tabs {
	display: flex;
	overflow-x: auto;
	margin: 0 -1.5rem;
  padding: 0 0 0 1.5rem;
	
	@media (min-width: @mq-m) {
		overflow: visible;
		margin: 0;
		padding: 0;
	}
}
.tab {
	font-family: @font-headings;
	color: @color-links;
	padding: 1rem 0;
	font-size: 1rem;
	white-space: nowrap;
	& + & {
		margin-left: 1.5rem;
	}
	&:last-child {
		padding-right: 1.5rem;
	}
	&[aria-selected="true"],
	&:hover,
	&:focus {
		.tab__label:after {
			opacity: 1;
		}
	}
}
.tab__label {
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: currentColor;
		opacity: 0;
		transition: .15s;
	}
}
.tabbed-content {
	&[aria-hidden="true"] {
		display: none;
	}
}
/*-----------------------------------------------------------------------------------------
	/Pagination
-----------------------------------------------------------------------------------------*/
.pagination-container {
	text-align: center;
	
}

.pagination {
	width: 100%;
	display: inline-block;
	padding: 3.625rem 0;
	> ul {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		position: relative;
		padding: 0 2.5rem;
		@media (min-width: @mq-m) {
			padding: 0;
		}
	}
}

.pagination-item {
	display: inline-block;
	&.prev {
		margin-right: auto;
		position: absolute;
		left: 0;
		@media (min-width: @mq-m) {
			position: static;
		}
		svg {
			transform: scaleX(-1);
		}
		@media (min-width: @mq-xl) {
			margin: 0;
			padding: 0 1rem 0 0;
			text-align: left;
			.pagination-next-prev {
				width: 2.5rem;
			}
		}
	}
	&.next {
		margin-left: auto;
		position: absolute;
		right: 0;
		@media (min-width: @mq-m) {
			position: static;
			margin-left: 4.5rem;
		}
		@media (min-width: @mq-xl) {
			.pagination-next-prev .label {
				margin-right: 0.5rem;
			}
		}
	}
}

.pagination-page {
	display: block;
	width: 3rem;
	height: 3rem;
	line-height: 3rem;
	text-align: center;
	border-radius: 50%;
	font-size: 1rem;
	color: @color-primary;
	.weight-medium;
	transition: background .15s;
	margin: 0 2px;

	&:hover,
	&:focus {
		color: @color-primary--dark;
	}

	.pagination-item.is-active & {
		color: @color-body;
		background: fade(@color-grey, 10%);
	}
}

.pagination-next-prev {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 24px;
	height: 24px;
	margin-top: -0.3em;
	color: @color-primary;
	transition: transform .3s;

	.pagination-item.disabled & {
		color: @color-body;
		opacity: .2;
		pointer-events: none;
	}

	@media (min-width: @mq-m) {
		min-height: 0;
		&:hover,
		&:focus {
			color: @color-links;
		}
	}
}
.side-matches-is-open{
    overflow: hidden;
}

.side-matches-bg{
    @media(min-width: @mq-m) {
        position:fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
        background-color: fade(black, 75%);
        visibility: hidden;
        opacity: 0;
        transition: 500ms @ease;

        .side-matches-is-open & {
            visibility: visible;
            opacity: 1;
        }
    }
}


.side-matches{
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    // padding: 2rem 1.5rem;
    background-color: white;
    overflow: auto;
    transform: translateX(100%);
    transition: transform 350ms @ease;
    transition-delay: 100ms;

    @media(min-width: @mq-m){
        max-width: 36rem;
        // padding: 4rem 3.5rem;
    }
    
    @media (min-width: @mq-xl) {
        &::-webkit-scrollbar {
            width: 17px;
        }
        &::-webkit-scrollbar-track {
            border-radius: 10em;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10em;
            background: fade(black, 20%);
            border: 5px solid white;
        }
    }

    .side-matches-is-open &{
        transform: translateX(0);
        transition: transform 500ms @ease;
    }
}

.side-matches__inner{
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.side-matches-header{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 1.625rem 1.5rem 1.5rem;
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 10;
    @media (min-width: @mq-m) {
        padding: 4.375rem 3.5rem 1.5rem;
    }
}

.side-matches-main {
    padding: 0 1.5rem;
    overflow-y: auto;

    @media (min-width: @mq-m) {
        padding: 0 3.5rem;
    }
}

.side-matches__list{
    margin: 0;
    margin-top: 1rem;
    padding: 0;
}

.side-matches__item{
    & + &{
        margin-top: .5rem;
    }
}

.side-matches__label{
    
}

.side-matches .side-matches__item-inner{
    align-items: flex-start;
    padding: 1rem 1rem 1rem .5rem;
    background-color: fade(@color-grey, 10%);
    border: 1px solid fade(@color-grey, 40%);
    border-radius: 4px;

    @media (min-width: @mq-m) {
        padding: 1rem 1.5rem 1rem 1rem;
    }
    @media (min-width: @mq-l) {
        padding: 1.5rem 2rem 1.5rem 1rem;
    }

    & > i{
        margin-top: 1.125rem;
        margin-right: .5rem;

        @media (min-width: @mq-m) {
            margin-top: 1.875rem;
        }
    }
}

.side-matches .side-matches__item-inner:hover,
.side-matches .filter input:checked ~ .side-matches__item-inner{
    background-color: fade(@color-primary, 8%);
    border: 1px solid fade(@color-primary, 40%);

    .cart-item__price-size{
        color: inherit;
    }
}

.side-matches__checkout{
    width: 100%;
    margin-top: 2rem;
    font-size: 1.4375rem;
    height: 4rem;
}

.side-matches-footer{
    margin-top: auto;
    padding: 0 1.5rem 2.5rem;
    background: #fff;
    position: sticky;
    bottom: 0;
    z-index: 10;

    @media (min-width: @mq-m) {
        padding-left: 3.5rem;
        padding-right: 3.5rem;
    }
}
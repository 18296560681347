.c-hero {
	position: relative;
	color: #fff;
	background: @color-primary;
}
.c-hero__body {
	padding: 3rem 0 2.625rem;
	position: relative;
	z-index: 2;
	@media (min-width: @mq-xl) {
		min-height: 17rem;
		padding: 5.625rem 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}
.c-hero__text {
	@media (min-width: @mq-l) {
		width: 45%;
	}
	@media (min-width: @mq-xl) {
		width: 33.333333%;
	}
}
.c-hero__media {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	&:before {
		content: "";
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 100%;
		background: fade(#182c44, 50%);
	}
	@media (min-width: @mq-l) {
		&:before {
			display: none;
		}
	}
}


.c-hero--inverted {
	@media (min-width: @mq-l) {
		color: @color-body;
		background: @color-divider;
	}
}
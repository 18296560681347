.cart-bg{
    @media(min-width: @mq-m) {
        position:fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
        background-color: fade(black, 75%);
        visibility: hidden;
        opacity: 0;
        transition: 500ms @ease;

        .cart-is-open & {
            visibility: visible;
            opacity: 1;
        }
    }
}

.cart{
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    // padding: 2rem 1.5rem;
    background-color: white;
    overflow: auto;
    transform: translateX(100%);
    transition: transform 350ms @ease;
    transition-delay: 100ms;

    @media(min-width: @mq-m){
        max-width: 36rem;
        // padding: 4rem 3.5rem;
    }
    
    @media (min-width: @mq-xl) {
        &::-webkit-scrollbar {
            width: 17px;
        }
        &::-webkit-scrollbar-track {
            border-radius: 10em;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10em;
            background: fade(black, 20%);
            border: 5px solid white;
        }
    }

    .cart-is-open &{
        transform: translateX(0);
        transition: transform 500ms @ease;
    }
}

.cart__inner{
    display: flex;
    flex-direction: column;
    min-height: 100%;
    // opacity: 0;
    // transform: translateY(2rem);
    // transition-delay: 500ms;

    // .cart-is-open & {
    //     opacity: 1;
    //     transform: translateY(0);
    //     transition: opacity 500ms @ease;
    //     transition-delay: 125ms;
    // }
}

.cart-header{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 1.625rem 1.5rem 1.5rem;
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 10;
    @media (min-width: @mq-m) {
        padding: 4.375rem 3.5rem 1.5rem;
    }
}

.cart-header__close{
    position: relative;
    width: 44px;
    height: 44px;
    margin-right: -10px;
    margin-bottom: -10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover, &:focus{
        color: @color-primary;
    }
}

.cart-header__heading{
    position: relative;
    font-size: 1.9375rem;
    line-height: 2rem;

    .cart-header__quantity{
        right: -1.5rem;
        background-color: @color-body;
        text-align: center;
    }
}

.cart-main {
    padding: 0 1.5rem;
    overflow-y: auto;
    @media (min-width: @mq-m) {
        padding: 0 3.5rem;
    }
}

.cart__list{
    margin: 0;
    margin-top: 1rem;
    padding: 0;
}

.cart-item{
    position: relative;
    display: flex;
    padding: 1.4375rem 0;
    border-top: 1px solid fade(@color-body, 16%);
}

.cart-item__image{
    position: relative;
    flex-shrink: 0;
    width: 4rem;
    height: 3rem;
    background-color: transparent;
    background-position: center center;
    @media (min-width: @mq-m) {
        width: 8rem;
        height: 6.5rem;
        border: 1px solid fade(@color-body, 16%);
    }
}

.cart-item__remove{
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cart-item__remove--mobile {
    color: @color-body;
    top: 18px;

    &:hover, &:focus{
        color: @color-red;
    }
    
    @media (min-width: @mq-m) {
        display: none;
    }
}
.cart-item__remove--thumb {
    color: white;
    background: fade(@color-body, 32%);

    &:hover, &:focus{
        background: @color-primary;
    }
    
    @media (max-width: (@mq-m - 0.0625em)) {
        display: none;
    }
}

.cart-item__info{
    flex: 1;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: 1rem;
}

.cart-item__title-make {
    padding-right: 2rem;
    margin-bottom: 0.75rem;
    flex: 1;
    @media (min-width: @mq-m) {
        margin-bottom: 1.6875rem;
    }
}

.cart-item__title{
    font-size: 1.0625rem;
    line-height: 1.0625;
    .weight-medium;
}

.cart-item__make{
    .small-caps;
    .weight-medium;
    margin-top: .25rem;
}

.cart-item__price-size {
    text-align: right;
    width: 90px;
    @media (max-width: (@mq-m - 0.0625em)) {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

.cart-item__price{
    font-size: 1.0625rem;
    line-height: 1rem;
    .weight-medium;
}
.cart-item__size {
    .small-caps;
    margin-top: .25rem;
}

.cart-item-quantity{
    width: 100%;
    flex-basis: 100%;
    margin-top: auto;
}

.cart-item-quantity__wrapper{
    display: inline-flex;
    flex-direction: row;
    width: 7rem;
    border-radius: .25rem;
    border: 1px solid fade(@color-body, 16%);

    > *{
        height: 2.5rem;
        flex: 0 0 33.333333%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.cart-item-quantity__operator{
    &:hover, &:focus {
        color: @color-primary;
    }
}

.cart-item-quantity__field {
    max-width: 33.333333%;
}
.cart-item-quantity__input {
    border: 0;
    background: 0;
    padding: 0;
    appearance: none;
    text-align: center;
    height: 100%;
    max-width: 100%;
    -moz-appearance: textfield;
    font-size: 1.0625rem;
    line-height: 1;
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    &:hover,
    &:focus {
        outline: 0;
        background: fade(@color-primary, 8%);
    }
}

.cart-item.cart-item--match{
    padding: 0;
    border-top: 0;

    & + &{
        margin-top: .5rem;
    }

    .cart-item__image{
        height: 3.5rem;
        background-color: #FFF;
        border: 0;
        
        @media (min-width: @mq-m) {
            width: 6rem;
            height: 5rem;
        }
    }

    .cart-item__title-make {
        @media (max-width: (@mq-m - 0.0625em)) {
            padding-right: 0;
            margin-bottom: 0;
        }
    }

    .cart-item__info{
        // margin-left: .5rem;

        @media (min-width: @mq-m) {
            margin-left: 1rem;
            padding-top: .5rem;
        }
    }

    .cart-item__price-size{
        @media (max-width: (@mq-m - 0.0625em)) {
            position: relative;
            display: flex;
            align-items: baseline;
            // flex-grow: 2;
            width: 100%;
            color: @color-grey;
        }
    }

    .cart-item__price{
        @media (max-width: (@mq-m - 0.0625em)) {
            order: 2;
            flex-grow: 2;
            text-align: right;
        }
    }
}

.cart-footer{
    margin-top: auto;
    padding: 0 1.5rem 2.5rem;
    background: #fff;
    position: sticky;
    bottom: 0;
    z-index: 10;
    @media (min-width: @mq-m) {
        padding-left: 3.5rem;
        padding-right: 3.5rem;
    }
}

.cart__amount {
    display: flex;
    justify-content: space-between;
    font-family: @font-headings;
    padding-top: 1.25rem;
    border-top: 1px solid fade(@color-body, 16%);
    @media (min-width: @mq-m) {
        font-size: 1.4375rem;
        line-height: 1.75;
    }
}

.cart__shipping{
    display: flex;
    justify-content: space-between;
}

.cart__shipping-link-wrapper{
    display: flex;
    justify-content: flex-end;
    margin-top: .5rem;
    text-transform: uppercase;
}

.cart__shipping-link{
    position: relative;
    .weight-medium;
    .small-caps;

    &:after{
        content: "";
        position: absolute;
        bottom: -.25rem;
        left: 0;
        display: block;
        width: 100%;
        height: .125rem;
        background: fade(@color-primary,20%);
        transition: .15s;
    }

    &:hover{
        &:after{
            background: @color-primary;
        }
    }
}

.cart__checkout{
    width: 100%;
    margin-top: 2rem;
    font-size: 1.4375rem;
    height: 4rem;
}

.cart__partners{
    display: flex;
    list-style: none;
    padding: 0;
    margin: 1rem 0 0;
    @media (min-width: @mq-m) {
        margin: 1.5rem 0 0;
    }
}

.cart__partner{
    & + &{
        margin-left: .75rem;
    }
}

.delivery-options {
    width: 100%;
    max-width: 53rem;
    padding: 1.5rem;
    @media (min-width: @mq-m) {
        padding: 4rem;
    }
}
.delivery-options__heading{
    margin-bottom: 2rem;
}

.delivery-options__table{
    width: 100%;
    border-collapse: collapse;

    tr{
        
        &:last-child{
            th,
            td{
                border-bottom: 1px solid fade(@color-body, 16%);
            }
        }
    }
    
    th {
		background: @color-primary;
		color: #fff;
		.small-caps;
		.weight-medium;
		text-align: left;
		padding: 0.8125rem 1rem;
        &:last-child {
            text-align: right;
        }
	}
    
    td{
        vertical-align: top;
        padding: 1.5rem 0;
        border-top: 1px solid fade(@color-body, 16%);
    }

    td {
        padding-left: 1rem;
        @media (min-width: @mq-m) {
            padding-left: 2rem;
        }
        &:first-child {
            padding-left: 0;
            .weight-medium;
        }
    }
}


.delivery-options__table-title {
    text-transform: uppercase;
}

.delivery-options__table-price{
    color: @color-primary;
    text-align: right;
}


.cart-is-open {
    overflow: hidden;
    @media (min-width: @mq-xl) {
        margin-right: 17px;
    }
}

.cart .cart__no-results {
    img {
        margin-top: 0;
    }
    .button {
        width: auto;
    }
}
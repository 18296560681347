.c-stars {
	width: 100%;
	display: flex;
	align-items: center;
	margin: 0 0 1rem;
}
.c-star {
	width: 1.5rem;
	height: 1.5rem;
	margin: 0 0.5rem 0 0;
}
.products-ui {
	padding-top: 2rem;
}

.products-ui__layout {
	@media (min-width: @mq-l) {
		display: grid;
		gap: @gutter-width;
		grid-gap: @gutter-width;
		grid-template:
			"productsSidebar productsResults" auto /
			0.25fr 0.75fr;
	}
}
.products-ui__mobile-toolbar {
	display: flex;
	margin: 0 0 1rem;
	border: 1px solid @color-divider;
	border-radius: @border-radius;
	@media (min-width: @mq-m) {
		margin: 0 0 2rem;
	}
	@media (min-width: @mq-xl) {
		display: none;
	}
	@media (min-width: @mq-l) {
		display: none;
	}
}
.products-ui__mobile-toolbar-col {
	position: relative;
	& + & {
		border-left: 1px solid @color-divider;
	}
}
.products-ui__mobile-toolbar-col--sidebar-toggle {
	flex: 1;
}
.products-ui__mobile-toolbar-col--sorting-toggle {
	width: 135px;
	select {
		position: absolute;
		top: 0;
		right: 0;
		opacity: 0;
	}
	@media (min-width: @mq-m) {
		width: 200px;
	}
}
.products-ui__sidebar-toggle {
	width: 100%;
	font-size: 1.0625rem;
	height: 3.29412em;
	border: 0;
	display: inline-flex;
	font-family: inherit;
	color: @color-primary;
	
	@media (min-width: @mq-l) {
		display: none;
	}
	
	.count {
		width: 1rem;
		height: 1rem;
		margin: 0 0 0 3px;
		background: @color-red;
		color: #fff;
		border-radius: 50%;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.75rem;
		line-height: 1;
		.weight-bold;
	}
}

.products-ui__sidebar {
	
	.filters {
		margin: 3.75rem 0 0;
		@media (max-width: (@mq-l - 0.0625em)) {
			margin: 0;
		}
		@media (min-width: @mq-l) {
			position: sticky;
			top: 3.5rem;
			padding-bottom: 10vh;
			&.is-sticky {
				max-height: 100vh;
				overflow-y: auto;
				transition: padding .35s @ease;
				&::-webkit-scrollbar {
					width: 8px;
				}
				&::-webkit-scrollbar-track {
					border-radius: 10em;
				}
				&::-webkit-scrollbar-thumb {
					border-radius: 10em;
					background: @color-divider;
				}
				
				.headroom--not-top.headroom--pinned & {
					padding-top: @header-height--condensed;
				}
			}
		}
	}

	@media (min-width: @mq-l) {
		grid-area: productsSidebar;
		display: block;
		height: 100%;
	}
}

.products-ui__results {
	.loading {
		margin-top: 3rem;
	}
	@media (min-width: @mq-l) {
		grid-area: productsResults;
	}
}

.products-toolbar {
	display: flex;
	align-items: center;
	padding: 0 0 2rem;
	@media (max-width: (@mq-l - 0.0625em)) {
		display: none !important;
	}
}

.products-toolbar__results {
	strong {
		.weight-bold;
	}
}
.products-toolbar__sorting {
	margin: 0 0 0 auto;
	display: flex;
	align-items: center;
	color: @color-primary;
}
.products-toolbar__sorting-label {
	margin: 0 0.5rem 0 0;
}
.products-toolbar__sorting-dropdown {
	position: relative;
	display: flex;
	align-items: center;
	select {
		font-size: 1em;
		border: 0;
		background: 0;
		color: currentColor;
		padding: 0.5em 0;
		&:focus,
		&:hover {
			outline: 0;
			color: @color-primary--dark;
		}
	}
	.icon {
		margin: 0 0 0 0.5rem;
	}
}

.products-grid {
	margin: 0;
	
	.c-prod-card {
		margin-bottom: 3rem;
	}
	
	@media (min-width: @mq-s) {
		display: flex;
		flex-wrap: wrap;
		margin-left: -1.5rem;
		margin-bottom: -3rem;
		.c-prod-card {
			width: 50%;
			padding-left: 1.5rem;
		}
	}
	
	@media (min-width: @mq-xl) {
		margin-left: -3rem;
		margin-bottom: -5rem;
		.c-prod-card {
			width: (100% / 3);
			padding-left: 3rem;
			margin-bottom: 5rem;
		}
	}
	
	.c-prod-card {
		
	}
	
	.products-no-results {
		width: 100%;
	}
}
.products-grid--full-width {
	@media (min-width: @mq-xxl) {
		.c-prod-card {
			width: (100% / 4);
			flex: 0 0 (100% / 4);
		}
	}
}


/*------------------------------------------------------------------------------
	/Empty state
------------------------------------------------------------------------------*/
.products-no-results {
	background: fade(@color-primary, 8%);
	color: @color-primary;
	padding: 3rem 1rem;
	text-align: center;

	@media (min-width: @mq-m) {
		padding: 7.5rem 3rem;
	}
	
	img {
		margin: 3em auto 1.5em;
	}
	p {
		margin-left: auto;
		margin-right: auto;
	}
}

/*------------------------------------------------------------------------------
    /Filters
------------------------------------------------------------------------------*/
.filters {

}

.filters__group {
	& + & {
		border-top: 1px solid fade(@color-body, 16%);
		padding: 1.625rem 0 0;
		margin: 1.625rem 0 0;
	}
}

.filters__list {
	.list-reset;
}

.filters__header {
	margin: 0 0 1.3125rem;
}
.filters__heading {
	font-family: @font-headings;
	.weight-medium;
	display: flex;
	align-items: center;
}
.filters__heading-info {
	width: 16px;
	height: 16px;
	margin: 0 0 0 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: @color-body;
	&:hover,
	&:focus {
		color: @color-links;
	}
}

.filter {
	display: flex;
	position: relative;
	label {
		width: 100%;
		cursor: pointer;
	}
	input {
		opacity: 0;
		position: absolute;
		&:hover,
		&:focus {
			~ .filter__label {
				color: @color-primary;
				i:before {
					border-color: @color-primary;
				}
			}
		}
		&:checked {
			~ .filter__label {
				color: @color-primary;
			}
		}
	}
}

.filter__label {
	padding: 5px 0;
	display: flex;
	align-items: center;
	transition: .15s;
	i {
		flex-shrink: 0;
		width: 20px;
		height: 20px;
		margin: 0 6px 0 0;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		&:before,
		&:after {
			content: "";
			display: block;
			border-radius: 50%;
			transition: .15s;
		}
		&:before {
			content: "";
			display: block;
			width: 16px;
			height: 16px;
			border: 2px solid fade(@color-body, 24%);
			border-radius: 50%;
			transition: .15s;
		}
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 20px;
			height: 20px;
			background: @color-primary;
			border-radius: 50%;
			opacity: 0;
			transition: .15s;
		}
	}
}

// Checkbox style
.filter input[type="checkbox"] {
	~ .filter__label i {
		&:after {
			background: @color-primary url('/images/icons/icon--filter-check.svg') no-repeat 50%;
		}
	}
	&:checked {
		~ .filter__label i:before {
			width: 20px;
			height: 20px;
			background: @color-primary;
			border-color: @color-primary;
		}
		~ .filter__label i:after {
			opacity: 1;
		}
	}
}

// Radio style
.filter input[type="radio"] {
	~ .filter__label i {
		&:after {
			width: 8px;
			height: 8px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	&:checked {
		~ .filter__label i:before {
			border-color: @color-primary;
		}
		~ .filter__label i:after {
			opacity: 1;
		}
	}
}

// Pill style
.filters__list--pill-style {
	display: flex;
	flex-wrap: wrap;
	.filter {
		margin: 0 4px 4px 0;
		input {
			&:hover,
			&:focus {
				~ .filter__label {
					border-color: @color-primary;
				}
			}
			&:checked {
				~ .filter__label {
					color: #fff;
					background: @color-primary;
				}
			}
		}
	}
	.filter__label {
		padding: 5px 12px;
		border-radius: 1rem;
		color: @color-primary;
		background: @color-primary--light;
		border: 1px solid transparent;
		i {
			display: none;
		}
	}
	.filter__label-text {
		font-size: 0.75rem;
		.weight-medium;
		text-transform: uppercase;
	}
}

.filter__label-list{
	flex-grow: 2;
}


/*------------------------------------------------------------------------------
	/Filters - actions
------------------------------------------------------------------------------*/
.filters-actions {
	display: flex;
	background: #fff;
	position: sticky;
	bottom: 0;
	right: 0;
	left: 0;
	width: 500px;
	max-width: 100vw;
	padding: 1.5rem;
	border-top: 1px solid @color-divider;
	@media (min-width: @mq-l) {
		display: none;
	}
	.button {
		flex: 1;
		border-radius: 0;
		margin: 0;
		&:first-child {
			border-radius: @border-radius 0 0 @border-radius;
		}
		&:last-child {
			border-radius: 0 @border-radius @border-radius 0;
		}
	}
}

/*------------------------------------------------------------------------------
	/Sizing table
------------------------------------------------------------------------------*/
.sizing-modal {
	width: 100%;
	max-width: 53rem;
	padding: 2rem 1.5rem;
	@media (min-width: @mq-m) {
		padding: 4rem;
	
	}
}
.sizing__heading {
	margin: 0 0 1em;
}
.sizing__table {
	width: 100%;
	border-collapse: collapse;
	th {
		background: @color-primary;
		color: #fff;
		.small-caps;
		.weight-medium;
		text-align: left;
		padding: 0.8125rem 1rem;
	}
	
	td {
		padding: 0.5rem 1rem;
		color: @color-primary;
		@media (max-width: (@mq-s - 0.0625em)) {
			font-size: 0.75rem;
		}
	}
	
	.col-metric,
	.col-imperial {
		text-align: right;
	}
	
	tr:nth-child(even) {
		td {
			background: @color-primary--light;
		}
	}
}
.sizing__table-title {
	display: flex;
	align-items: center;
	.icon {
		width: 3.5rem;
		height: 3.5rem;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin: 0 1rem 0 0;
	}
	.text {
		.weight-medium;
	}
}


/*------------------------------------------------------------------------------
    /Themes
------------------------------------------------------------------------------*/
.theme--sale {
	.c-hero {
		background: @color-red;
		
	}
	.c-hero__layout {
		position: relative;
		@media (min-width: @mq-m) {
			&:after {
				content: "";
				width: 264px;
				height: 190px;
				background: url('/images/sale-illustration.svg') no-repeat 50%;
				background-size: contain;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
			}
		}
	}
	.c-hero__text {
		@media (min-width: @mq-m) {
			width: 45%;
		}
	}
	.c-hero__media {
		display: none;
	}
	.c-prod-card__size-info-price {
		color: @color-red;
	}
}
.template--home {
	.c-best__layout {
		border-top: 0;
		padding-top: 0;
	}
}

.home-feature {
	img {
		width: 100%;
	}
}



.portals {
	margin: 1.5rem 0 0;
	@media (min-width: @mq-m) {
		margin: 4rem 0 0;
	}
}

.portals__layout {
	display: flex;
	flex-wrap: wrap;
	
	@media (min-width: @mq-xl) {
		margin-left: -3rem;
	}
	
	// display: grid;
	// grid-template-columns: repeat(2, 1fr);
	// grid-template-rows: 1fr 1fr;
	// gap: 3rem;
}

.portal {
	width: 100%;
	
	
	@media (min-width: @mq-xl) {
		width: 50%;
		padding-left: 3rem;
	}
	
	& + & {
		margin-top: 1.5rem;
		@media (min-width: @mq-m) {
			margin-top: 3rem;
		}
	}
	&:first-child {
		width: 100%;

		.portal__link:before {
			padding-bottom: 54%;
		}
	}
}
.portal__link {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 100%;
	position: relative;
	color: #fff;
	&:hover,
	&:focus {
		.button {
			background: @color-primary;
			color: #fff;
		}
	}
}
.portal__media {
	// height: 808px;
	// position: absolute;
	// top: 0;
	// right: 0;
	// bottom: 0;
	// left: 0;
	background-color: @color-grey;
	
	height: 90vw;
	max-height: 50rem;
	
	&:after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0.5;

		// background: linear-gradient(208.93deg, rgba(0, 0, 0, 0) 70.76%, #000 100%);
		background: linear-gradient(197.69deg, rgba(0, 0, 0, 0) 40.58%, #000 100%);

	}
	
	@media (min-width: @mq-m) {
		height: auto;
		max-height: none;
		.img-resizer {
			.aspect-ratio--16x9;
		}
	}
	@media (min-width: @mq-xl) {
		height: 90vw;
		max-height: 50rem;
		.img-resizer {
			padding: 0;
		}
	}
}
.portal__text {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	padding: 1.5rem;
	@media (min-width: @mq-m) {
		padding: 3rem;
	}
	.button {
		margin: 0.875rem 0 0;
	}
}


.home-ctas__grid {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	gap: 1.5rem;
	
	@media (min-width: @mq-xl) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: auto;
		gap: 3rem;
	}

}

.home-cta {
	height: calc(100vw - 3rem);
	@media (min-width: @mq-m) {
		height: 75vw;
		height: auto;
	}
	@media (min-width: @mq-l) {
		height: 56.25vw;
	}
	@media (min-width: @mq-xl) {
		height: calc(100vh - 14rem);
		max-height: 50rem;
	}
}
.home-cta__link {
	display: flex;
	height: 100%;
	position: relative;
	color: @color-primary;
	&:hover,
	&:focus {
		.button {
			color: @color-primary--dark;
			background: @color-secondary;
		}
	}
	@media (min-width: @mq-m ) and (max-width: (@mq-l - 0.0625em)) {
		height: auto;
		padding-bottom: 75%;
	}
}
.home-cta__media {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	@media (max-width: (@mq-m - 0.0625em)) {
		img {
			object-fit: cover;
			font-family: 'object-fit: cover;';
		}
	}
	// @media (min-width: @mq-m ) and (max-width: (@mq-l - 0.0625em)) {
	// 	.img-resizer {
	// 		.aspect-ratio--4x3;
	// 	}
	// }
}
.home-cta__illustration {
	margin: 0 0 1rem;
	width: 65%;
	max-width: 17rem;
	@media (min-width: @mq-l) {
		width: auto;
		margin: 0 0 2.625rem;
	}
}
.home-cta__text {
	flex: 1;
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 2rem 1.5rem 1.5rem;
	max-width: 80%;
	@media (min-width: @mq-m) {
		padding: 4.25rem 3rem 3rem;
		max-width: 28rem;
	}
	@media (min-width: @mq-m ) and (max-width: (@mq-l - 0.0625em)) {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
	}
	.button {
		margin-top: auto;
		.icon:first-child {
			margin-left: -12px;
		}
	}
}
.home-cta__heading {
	line-height: 1.5;
	@media (max-width: (@mq-s - 0.0625em)) {
		font-size: 1rem;
	}
}
.home-cta--about {
	.home-cta__media:after {
		content: "";
		position: absolute;
		width: 18%;
		height: 100%;
		top: 0;
		left: 0;
		bottom: 0;
		background: linear-gradient(90deg, #DDD2CE 35.42%, rgba(221, 210, 206, 0) 100%);
	}
}

.home-cta--stores {
	background: @color-primary;
	background-size: contain;
	color: #fff;
	.home-cta__link {
		color: currentColor;
	}
	.home-cta__media {
		img {
			object-position: 100% 100%;
		}
	}
}


/*------------------------------------------------------------------------------
	/Featured reviews
------------------------------------------------------------------------------*/
.featured-reviews {
	overflow: hidden;
}
.featured-reviews__header {
	margin: 0 0 2.5rem;
	@media (min-width: @mq-m) {
		margin: 0 0 4rem;
	}
}
.featured-reviews__list {
	flex-wrap: nowrap;
	width: 100vw;
	scroll-snap-type: x mandatory;
	overflow-x: auto;
	padding: 0 0 1rem 1.5rem;
	margin: 0 -1.5rem -1rem;
	// @media (min-width: @mq-m) {
	// 	padding-left: 3.5rem;
	// 	margin-left: -3.5rem;
	// 	margin-right: -3.5rem;
	// }
	@media (min-width: @mq-l) {
		width: 100%;
		overflow: visible;
		margin: 0 -1.5rem;
		padding: 0;
	}
}
.featured-review {
	min-width: 80vw;
	scroll-snap-align: center;
	padding: 0 1rem 0 0;
	@media (min-width: @mq-l) {
		min-width: 0;
		padding: 0 @half-gutter-width;
	}
}
.featured-review__stars {
	.c-star {
		width: 2rem;
		height: 2rem;
	}
}
.featured-review__quote {
	footer {
		margin-top: 1em;
	}
}
.featured-review__quote-text {
	font-family: @font-headings;
	line-height: 1.5;
	@media (min-width: @mq-m) {
		font-size: 1.4375rem;
		line-height: 1.4;
	}
	.qm-s {
		font-size: 2em;
		line-height: 1;
		margin: 0 0.1em 0 0;
		position: relative;
		top: 0.18em;
	}
}


/*------------------------------------------------------------------------------
	/Brands
------------------------------------------------------------------------------*/
.brands {
	color: @color-primary;
	background: @color-primary--light;
	overflow: hidden;
	padding: 3rem 0 3.5rem;
	@media (min-width: @mq-m) {
		padding: 6rem 0;
	
	}
}
.brands__layout {
	align-items: center;
}
.brands__header {
	margin: 0 0 2rem;
	text-align: center;
	@media (min-width: @mq-xxl) {
		margin: 0 0 auto;
    	padding-top: 0.5em;
		text-align: left;
	}
}
.brands__logos {
	// display: grid;
	// grid-template-columns: repeat(2, 1fr);
	// grid-template-rows: auto auto;
	// gap: 3rem;
	
	@media (max-width: (@mq-m - 0.0625em)) {
		position: relative;
		width: 100vw;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		scroll-snap-type: x mandatory;
		overflow-x: auto;
		padding: 0 0 1rem 1.5rem;
		margin: 0 -1.5rem -1rem;
		max-width: 100vw;
		flex: 1 0 100vw;
	}
	
	@media (min-width: @mq-m) {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		gap: 3rem;
	}
	@media (min-width: @mq-m) and (max-width: (@mq-xxl - 0.0625em)) {
		max-width: 62.5rem;
		margin: auto;
	}
	@media (min-width: @mq-xxl) {
		// flex-wrap: nowrap;
		justify-content: flex-start;
	}
}
.brands__logo {
	
	@media (max-width: (@mq-m - 0.0625em)) {
		min-width: 40vw;
		scroll-snap-align: center;
		padding: 0 2.375rem 0 0;
	}
	@media (min-width: @mq-s) {
		min-width: 130px;
	}
	@media (min-width: @mq-m) {
		margin-right: 1rem;
		margin-left: 1rem;
	}
	@media (min-width: @mq-xxl) {
		margin-right: 2rem;
		margin-left: 0;
	}
	a {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	img {
		max-height: 3.5rem;
	}
}


.home-feature{
	.flickity-page-dots{
		position: static;
		display: block;
		margin-top: 1.4375rem;
		@media(min-width: @mq-m) {
			margin-top: 2.1875rem;
			margin-bottom: -1.25rem;
		}
		.dot{
			margin: 0;
			background-color: fade(@color-body, 20%);
			opacity: 1;
		}
		.dot.is-selected{
			position: relative;
			background-color: @color-primary;
		}
		.dot.is-selected:before{
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 1.125rem;
			height: 1.125rem;
			border: 2px solid @color-primary;
			border-radius: 100%;
			transform: translate(-50%, -50%);
		}
		.dot + .dot{
			margin-left: 0.875rem;
		}
	}

}
.home-feature__slide{
	width: 100%;
	position: relative;
	
	&.--is-link{
		cursor: pointer;
	}
}
.home-feature__picture-wrap{
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-bottom: 93%;

	@media(min-width: @mq-s) {
		padding-bottom: 50.78125%;
	}
	@media(min-width: @mq-l) {
		padding-bottom: 38.5%;
	}
}
.home-feature__picture{
	position:absolute;
	top:0; right:0; bottom:0; left:0;
	width:100%; height: 100%;
}

.home-feature__caption{
	padding-top: .625rem;
	font-size: .75rem;
	line-height: 1.2;
	color: @color-body;
}
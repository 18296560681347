.template--stores {
	.header {
		border-bottom: 0;
	}
}

.stores-ui {
	position: relative;
	background: #BAD4E8;
	height: calc(100vh - @header-height--mobile);
	@media (min-width: @mq-xl) {
		height: calc(100vh - @header-height);
	}
}
.stores-ui__inner {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}
.stores-ui__layout {
	width: 100%;
	height: 100%;
	@media (min-width: @mq-l) {
		display: flex;
	}
}
.stores-ui__toggle {
	position: absolute;
	z-index: 3;
	bottom: 5rem;
	right: 1.3125rem;
	width: 56px;
	height: 56px;
	background: #fff;
	color: @color-primary;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (min-width: @mq-l) {
		display: none;
	}
	.icon {
		width: 24px;
		height: 24px;
	}
	svg {
		max-width: 100%;
	}
	.icon--map {
		display: none;
	}
}



/*------------------------------------------------------------------------------
	/Panel
------------------------------------------------------------------------------*/
.stores-ui__panel {
	position: fixed;
	top: @header-height--mobile;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	// height: 5.5rem;
	height: calc(100vh - @header-height--mobile);
	background: @color-primary--light;
	transform: translateX(-101%);
	transition: transform .35s @ease;
	@media (min-width: @mq-l) {
		position: relative;
		z-index: 5;
		top: 0;
		padding: 5rem 0 5rem 3.5rem;
		width: 26rem;
		height: auto;
		background: 0;
		transform: none;
		transition: none;
	}
	@media (min-width: @mq-l) and (max-height: 48em) {
		padding: 5vh 0 5vh 5vh;
	}
	@media (min-width: @mq-xl) {
		width: 28rem;
	}
	@media (min-width: @mq-xxl) {
		width: 32.5rem;
	}
}
.stores-ui__panel-inner {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: 100%;
	@media (min-width: @mq-l) {
		border-radius: @border-radius;
	}
}
.stores-ui__panel-header {
	color: @color-primary;
	padding: 1.5rem 1.5rem 0.5rem;
	
	@media (min-width: @mq-l) {
		background: #fff;
		border-radius: @border-radius @border-radius 0 0;
		padding: 1.5rem;
	}
	@media (min-width: @mq-xxl) {
		padding: 2.25rem 2.5rem 2.5rem;
	}
	h1 {
		margin: 0 0 0.5625rem;
		display: none;
		@media (min-width: @mq-l) {
			display: block;
		}
	}
}

.stores-ui__search {
	
}
.stores-ui__search-field {
	position: relative;
	&:hover {
		.stores-ui__search-input {
			border-color: @color-primary;
		}
	}
}
.stores-ui__search-input {
	
	color: @color-primary;
	width: 100%;
	height: 3.5rem;
	padding: 0 0 0 3.5rem;
	border-radius: @border-radius;
	border: 2px solid transparent;
	background: #fff;
	@media (min-width: @mq-l) {
		border: 1px solid tint(@color-primary, 84%);
		background: @color-primary--light;
	}
	&::placeholder {
		color: currentColor;
		opacity: 0.5;
	}
	&:focus,
	&:hover {
		outline: 0;
		border-color: @color-primary;
	}
}
.stores-ui__search-submit {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 3.5rem;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	transition: .15s;
	&:hover,
	&:focus {
		color: @color-primary--dark;
	}
}

.stores-ui__panel-results {
	overflow-y: auto;
	background: @color-primary--light;
	// padding: 0 calc(1.5rem - 17px) 0 1.5rem;
	padding: 0 1.5rem 1.5rem;
	flex: 1;
	// opacity: 0;
	// pointer-events: none;
	@media (min-width: @mq-l) {
		border-top: 1px solid tint(@color-primary, 84%);
		padding: 1.5rem;
		opacity: 1;
		pointer-events: auto;
	}
	@media (min-width: @mq-xl) {

		&::-webkit-scrollbar {
			width: 17px;
		}
	
		&::-webkit-scrollbar-track {
			border-radius: 10em;
		}
	
		&::-webkit-scrollbar-thumb {
			border-radius: 10em;
			background: fade(@color-primary, 11%);
			border: 5px solid @color-primary--light;
		}
	}
	@media (min-width: @mq-xxl) {
		padding: 2.5rem 2.5rem 0;
	}
	
}
.stores-ui__results {
	.list-reset;
	padding: 0 0 2.5rem;
}
.stores-ui__results-item {
	& + & {
		margin: 0.5rem 0 0;
	}
	.title {
		.weight-bold;
	}
}
.stores-ui__results-item--empty {
	.stores-ui__result {
		border: 0;
	}
}
.stores-ui__result {
	display: flex;
	align-items: center;
	color: @color-primary;
	background: #fff;
	border-radius: @border-radius;
	border: 2px solid transparent;
	padding: 1rem 1.5rem;
	transition: .15s;

	@media (min-width: @mq-l) {
		padding: 1.375rem 1.5rem;
	}
	
	.icon {
		margin: 0 1rem 0 0;
	}
	.text {
		
	}
	.detail {
		color: @color-body;
		font-size: 0.88em;
	}
	.arrow {
		margin: 0 0 0 auto;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	&:hover,
	&:focus {
		outline: 0;
		border-color: @color-primary;
	}
}


/*------------------------------------------------------------------------------
	/Map
------------------------------------------------------------------------------*/
.stores-ui__map {
	position: relative;
	// width: calc(100% - 32.5rem);
	flex: 1;
	height: 100%;
	margin: 0 0 0 auto;
	outline: 0;
	
	// opacity: 0;
	
	* {
		outline: 0;
	}
	
	@media (min-width: @mq-l) {
		margin-left: -8rem;
		&:before {
			content: "";
			width: 8rem;
			height: 100%;
			background: linear-gradient(90deg, #BAD4E8, fade(#BAD4E8, 0%));
			position: absolute;
			z-index: 2;
			top: 0;
			left: 0;
			bottom: 0;
			pointer-events: none;
		}
	}
}
.stores-ui__map-canvas {
	position: absolute;
	width: 100%;
	height: 100%;
	outline: 0;
}


@keyframes storeInfoWindow {
	from {
		opacity: 0;
		transform: translateY(1em);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

.si-float-wrapper {
	animation: storeInfoWindow .4s @ease;
}

.store-info-window {
	display: block;
	width: 20rem;
	padding-bottom: 0.5em;
	
	@media (min-width: @mq-l) {
		width: 21rem;
	}
	
	* {
		font-family: @font-body;
	}
	
	a {
		display: block;
		color: @color-primary;
		&:hover,
		&:focus {
			color: @color-primary--dark;
		}
	}
	
	.si-content-wrapper {
		overflow: hidden;
		padding: 0;
		background: #fff;
		border-radius: @border-radius;
		box-shadow: 0px 4px 16px rgba(0, 94, 184, 0.16);
	}
	
	.si-pointer-top{
		width: 0;
		height: 0;
		left: 50%;
		margin-left: -15px;
		margin-top: -7px;
	}

	.si-close-button{ display: none; }
	
	.biw-image {
		background: @color-divider;
		img{
			max-width: 100%;
		}
	}
	
	.biw-text {
		padding: 1rem 1.5rem 1.5rem;
		font-size: 1rem;
	}
	
	.biw-title {
		.weight-bold;
		line-height: 1.4;
	}
	
	.biw-address {
		font-size: 0.75rem;
		line-height: 1.333;
		text-transform: uppercase;
	}
	
	.biw-phone {
		color: @color-body;
		font-size: 0.9375rem;
		margin: 0.5em 0 0;
	}
}


// Map zoom buttons
.map-zoom {
	position: absolute;
	top: 1.5em;
	right: 1em;
	z-index: 1;
	overflow: hidden;
	border: 1px solid @color-primary--light;
	border-radius: @border-radius;
	display: none;

	@media (min-width: @mq-l) {
		display: block;
		top: auto;
		right: 3.5rem;
		bottom: 3.5rem;
	}
}
.button-map-zoom {
	width: 40px;
	height: 40px;
	background: #fff;
	color: @color-primary;
	transition: background-color .15s, color .15s;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover,
	&:focus {
		background-color: @color-primary;
		color: #fff;
		outline: 0;
	}
}
.button-map-zoom--in {
	border-bottom: 1px solid @color-primary--light;
}


/*------------------------------------------------------------------------------
	/State
------------------------------------------------------------------------------*/
.store-finder-is-active {
	overflow: hidden;
	@media (max-width: (@mq-l - 0.0625em)) {
		.stores-ui__toggle {
			background: @color-primary;
			color: #fff;
			
			.icon--list { display: none; }
			.icon--map { display: block; }
		}
		.stores-ui__panel {
			transform: none;
		}
		// .stores-ui__panel-header {
		// 	background: @color-primary--light;
		// 	transition: background .2s;
		// }
		// .stores-ui__panel-results {
		// 	opacity: 1;
		// 	pointer-events: auto;
		// 	transition: opacity .2s;
		// }
	}
}


/*-----------------------------------------------------------------------------------------
    /Address autocomplete
-----------------------------------------------------------------------------------------*/
.pac-container {
	font-family: inherit;
	border: 0;
	// width: 90vw !important;
	box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
	@media (min-width: @mq-xl) {
			max-width: 26.5625rem;
	}
}
.pac-item {
	padding: 0.5rem 1.5rem;
}
.pac-icon {
	display: none;
}
.pac-matched {
	color: @color-primary;
	background: fade(@color-primary, 5%);
}
.pac-logo {
	&:after {
			background-color: #f3f3f3;
			padding: 5px;
			height: 25px;
	}
}
/*-----------------------------------------------------------------------------------------
	/Base
-----------------------------------------------------------------------------------------*/
html,body {
	padding: 0;
	margin: 0;
	height: 100%;
}
html {
	font-size: 100%;
	scroll-behavior: smooth;
}
body {
	color: @color-body;
	font-family: @font-body;
	font-size: 1rem;
	line-height: 1.4;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	-webkit-font-smoothing: antialiased;
	-moz-font-feature-settings: "liga", "kern";
	-moz-font-feature-settings:"liga=1, kern=1";
	-ms-font-feature-settings:"liga", "kern";
	-o-font-feature-settings:"liga", "kern";
	-webkit-font-feature-settings:"liga", "kern";
	font-feature-settings: "liga", "kern";

	@media(min-width: 100em) {
		font-size: 1.0625rem;
	}
}
a {
	color: @color-links;
	transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out;
	text-decoration: none;
}
p {
	margin-top: 0;
	margin-bottom: 1.5em;
	max-width: 76ch;

	&:last-child {
		margin-bottom: 0;
	}
}
.js-focus-visible *:focus:not(.focus-visible) {
	outline: 0;
}
p a:not(.button),
.link,
.wysiwyg a:not(.button) {
	border-bottom: 1px solid currentColor;
	transition: .15s border-color, .15s color, .15s box-shadow;
	outline: none;
	color: @color-links;
	// display: inline-block;

	&:hover,
	&:focus {
		outline: none;
		color: @color-primary--dark;
		border-color: @color-primary--dark;
	}
}
.wysiwyg {
	h1,
	h2,
	h3 {
		margin-bottom: 1em;
	}
	figure + * {
		margin-top: 1em;
	}
}
.external-link {
	display: inline-flex;
	align-items: center;
	span {
		border-bottom: 1px solid currentColor;
		transition: .15s;
	}
	&:hover,
	&:focus {
		color: @color-primary--dark;
	}
}
em {
}
.no-outline:focus {
	outline: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid @color-divider;
    margin: 3em 0 3em;
    padding: 0;
    clear: both;
}
html ::selection { 			background: @color-primary; color: white;}
html ::-moz-selection{ 		background: @color-primary; color: white;}

iframe {
	border: none;
}
figure {
	margin: 0;
}
figcaption {
	margin: 1.7em 0 0;
	font-size: .9em;
	position: relative;
	max-width: 54em;
	padding: 0 0 0 2.4em;

	&:before {
		content: "";
		height: 2px;
		background: @color-body;
		display: inline-block;
		vertical-align: middle;
		position: absolute;
		left: 0;
		top: 0.75em;
		width: 1.5em;
	}
}
b,
strong {
	.weight-medium;
}
small {
	font-size: 0.76em;
}

.inline-icon-wrap { //https://snook.ca/archives/html_and_css/icon-wrap-after-text
	display: inline;
	white-space: nowrap;
}
.icon {
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: .15s;
}
.icon--l {
	margin-right: 8px;
}
.icon--r {
	margin-left: 8px;
}
.icon--solid {
	width: 32px;
	height: 32px;
	background: @color-primary;
	color: #fff;
	border-radius: 50%;
}
.icon--8 {
	width: 8px;
	height: 8px;
}
.icon--16 {
	width: 16px;
	height: 16px;
}
.icon--24 {
	width: 24px;
	height: 24px;
}
.icon--32 {
	width: 32px;
	height: 32px;
}

.disable-transitions,
.disable-transitions * {
	transition: none !important;
}

@media (prefers-reduced-motion) {
	* {
		transition: none !important;
	}
}

/*-----------------------------------------------------------------------------------------
	/Images
-----------------------------------------------------------------------------------------*/
.image-bg {
	background-position: center;
	background-size: cover;
	background-color: fade(@color-primary, 10%);
	background-repeat: no-repeat;
}

.bg-cover {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.bg-contain {
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-color: #fff;
}

figure,
.image-wrap {
	position: relative;
	overflow: hidden;
}

img {
	border: none;
	height: auto;
}

.img-resizer {
	float: left;
}


.object-fit {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.object-cover {
	object-fit: cover;
	&.object-top {
		object-position: top;
	}
	
	&.object-bottom {
		object-position: bottom;
	}
}
.object-contain {
	object-fit: contain;
}

.lazy {
	opacity: 0;
	transition: opacity .2s;
	&.loading {
		opacity: 0;
	}
	&.loaded {
		opacity: 1;
	}
	&.loaded ~ .content-placeholder {
		opacity: 0;
	}
}

/*-----------------------------------------------------------------------------------------
	/Main
-----------------------------------------------------------------------------------------*/
.container {
	position: relative;
	flex: 1;
	margin-top: @header-height--mobile;
	@media (min-width: @mq-l) {
		margin-top: @header-height--tablet;
	}
	@media (min-width: @mq-xl) {
		margin-top: @header-height;
	}
}
.site-main {
}


/*--------------------------------------------------------------------------------
	/Loading spinner
--------------------------------------------------------------------------------*/
.loading {
	position: relative;
	width: 100%;
	height: 4.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: fade(@color-primary, 10%);
	border-radius: @border-radius;
	font-size: 0;
	color: transparent;
	user-select: none;
	pointer-events: none;
	
	&:before {
		position: absolute;
		content: "";
		display: block;
		width: 8px;
		height: 8px;
		background: @color-primary;
		border-radius: 50%;
		box-shadow: 12px 0 fade(@color-primary, 25%), -12px 0 @color-primary;
		animation: loading-dots 0.5s infinite linear alternate;
	}
	
	&:not(.htmx-request) {
		display: none;
	}
	
	&.htmx-request ~ * {
		visibility: hidden;
	}
}
@keyframes loading-dots {
    0% {box-shadow: 12px 0 @color-primary,-12px 0 fade(@color-primary, 25%);background: @color-primary}
    33%{box-shadow: 12px 0 @color-primary,-12px 0 fade(@color-primary, 25%);background: fade(@color-primary, 25%)}
    66%{box-shadow: 12px 0 fade(@color-primary, 25%),-12px 0 @color-primary;background: fade(@color-primary, 25%)}
}

// @keyframes loading-spinner {
// 	0%{transform:rotate(0)}
// 	100%{transform:rotate(360deg)}
// }
// .loading {
// 	display: block;
// 	width: 90%;
// 	height: 20vh;
// 	position: relative;
// 	box-sizing: border-box;
// 	overflow: hidden;
// 	text-indent: -5000px;
// 	margin: 2em auto;
// 	&:before {
// 		border-radius: 50%;
// 		content: " ";
// 		width: 64px;
// 		height: 64px;
// 		display: inline-block;
// 		box-sizing: border-box;
// 		border: 3px solid #bababa;
// 		position: absolute;
// 		top: 50%;
// 		left: 50%;
// 		margin-top: -32px;
// 		margin-left: -32px;
// 	}
// 	&:after {
// 		border-radius: 50%;
// 	    content: " ";
// 	    width: 64px;
// 	    height: 64px;
// 	    display: inline-block;
// 	    box-sizing: border-box;
// 	    border-top: 3px solid @color-primary;
// 	    border-right-color: transparent;
// 	    border-bottom-color: transparent;
// 	    border-left-color: transparent;
// 	    position: absolute;
// 	    top: 50%;
// 	    left: 50%;
// 			margin-left: -32px;
// 			margin-top: -32px;
// 	    animation: loading-spinner .31s linear infinite;
// 	 }
// 	 ~ * {
// 		 display: none;
// 	 }
// }




/*-----------------------------------------------------------------------------------------
	/Plugin overrides
-----------------------------------------------------------------------------------------*/
.fancybox-bg {
	background: #000;
}

.fancybox-is-open .fancybox-bg {
	opacity: .75;
}
.fancybox-toolbar {
	right: 0;
    top: 0.5em;
    left: 0;
    display: flex;
	justify-content: center;
	// opacity: 1;
	// visibility: visible;
}
.fancybox-content {
	border-radius: @border-radius;
	max-height: 90%;
	@media (max-width: (@mq-m - 0.0625em)) {
		max-height: 85%;
	}
	&::-webkit-scrollbar {
		width: 17px;
	}
	&::-webkit-scrollbar-track {
		border-radius: 10em;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 10em;
		background: fade(black, 20%);
		border: 5px solid white;
	}
}
.fancybox-button {
	background: 0;
	color: #fff;
}
.flickity-prev-next-button {
	background: url('/images/icon--arrow.svg') no-repeat 50%/contain;
	width: 32px;
	height: 14px;
	position: static;
	transform: none;
	svg {
		display: none;
	}
	&.previous {
		transform: scaleX(-1);
		margin-left: -10px;
		+ .next {
			margin-left: 10px;
		}
	}
	&:hover,
	&:focus {
		background: url('/images/icon--arrow.svg') no-repeat 50%/contain;
		box-shadow: none;
	}
}
.fancybox-close-small {
	color: @color-body;
}
.fancybox-slide--html {
	.fancybox-close-small {
		top: 0.5rem;
		right: 0.5rem;
	}
}
.footer {
	background: @color-primary;
	color: #fff;
	a {
		color: currentColor;
	}
}
.footer__inner {
	@media (max-width: (@mq-m - 0.0625em)) {
		padding-left: 0;
		padding-right: 0;
	}
}
.footer__body {
	padding: 2.625rem 0;
	@media (min-width: @mq-m) {
		padding: 4rem 0 0;
	}
	@media (min-width: @mq-xl) {
		padding: 8rem 0 6rem;
	}
	+ .footer__end {
		border-top: 1px solid fade(#fff, 16%);
	}
	> .grid-col {
		+ .grid-col {
			@media (max-width: (@mq-m - 0.0625em)) {
				border-top: 1px solid fade(#fff, 16%);
				padding-top: 2.625rem;
				margin-top: 2.625rem;
			}
		}
		@media (min-width: @mq-m) and (max-width: (@mq-xl - 0.0625em)) {
			margin-bottom: 4rem;
		}
		@media (min-width: @mq-m) and (max-width: (@mq-l - 0.0625em)) {
			padding-left: 0;
		}
	}
}
.footer__heading {
	margin: 0 0 1.5rem;
	@media (max-width: (@mq-m - 0.0625rem)) {
		font-size: 1.1875rem;
		line-height: 0.84;
		margin-bottom: 1.875rem;
	}
}
.footer__nav {
	.list-reset;
}
.footer__nav-item {
	margin: 0 0 0.5rem;
}
.footer__nav-link {
	display: inline-flex;
	align-items: center;
	&:hover,
	&:focus {
		outline: 0;
		.footer__nav-link-label {
			border-bottom-color: currentColor;
		}
	}
}
.footer__nav-link-label {
	border-bottom: 1px solid transparent;
	transition: border .15s;
}
.footer__social {
	.list-reset;
	display: flex;
	flex-wrap: wrap;
	> * {
		margin: 0 0.5rem 0.5rem 0;
	}
}

.footer__end {
	padding-top: 3.3rem;
	padding-bottom: 2.7rem;
	@media (min-width: @mq-l) {
		padding: 4.5rem 0 5.5rem;
		display: flex;
		align-items: center;
		flex-direction: row;
	}
}
.footer__logos {
	.list-reset;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin: -0.4rem 0 1.625rem -0.4rem;
	
	@media (min-width: @mq-l) {
		padding: 0 2rem 0 0;
		margin: 0 0 0 -0.4rem;
	}
}
.footer__logos-item {
	margin: 0.4rem;
}
.footer__copyright {
	font-size: 0.8125rem;
	@media (min-width: @mq-l) {
		font-size: 1rem;
		text-align: right;
		margin: 0 0 0 auto;
	}
}

.notification {
	background: @color-primary;
	color: #fff;
	padding: 2.5rem 1.5rem 3.5rem;
	position: fixed;
	z-index: 9000;
	bottom: 0;
	right: 0;
	left: 0;
	box-shadow: 0px -2px 16px rgba(0, 0, 0, 0.1);
	
	@media (min-width: 48em) {
		bottom: 2rem;
		left: 50%;
		transform: translateX(-50%);
		width: 45rem;
		padding: 1.5rem 1.5rem 2rem;
		max-width: calc(100% - 4rem);
	}
}
.notification__layout {
	display: flex;
	justify-content: space-between;
	
}
.notification__text {
	padding: 0 1.5rem 0 0;
	p a {
		border-bottom: 1px solid currentColor;
		transition: .15s;
		color: #fff;
	
		&:hover,
		&:focus {
			color: currentColor;
			border-color: currentColor;
		}
	}
	@media (min-width: @mq-m) {
		padding-right: 3.5rem;
	}
}

.notification-is-active {
	touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;

	.sizing-modal.fancybox-content{
		display: inline-block;
	}

	.fancybox-slide--html{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	// .footer {
	// 	padding-bottom: 15rem;
	// 	@media (min-width: @mq-m) {
	// 		padding-bottom: 11.25rem;
	// 	}
	// }
}
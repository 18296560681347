.blog-header {
	padding: 4.75rem 0;
	background: @color-body;
	color: #fff;
	@media (min-width: @mq-l) {
		background: 0;
		color: currentColor;
	}
	
	.grid-row {
		align-items: center;
	}
}

.blog-header__filters-toggle {
	width: 100%;
	max-width: 200px;
	font-size: 1.0625rem;
	margin-left: auto;
	height: 3.29412em;
	border: 1px solid fade(#fff, 16%);
	color: #fff;
	display: inline-flex;
	flex-direction: row;
	font-family: inherit;
	
	&:hover,
	&:focus {
		border-color: #fff;
	}

	@media (min-width: @mq-l) {
		display: none;
	}
}

.blog-header__filters {
	@media (min-width: @mq-l) {
		display: flex;
		justify-content: center;
	}
	
	.filters__header {
		@media (min-width: @mq-l) {
			display: none;
		}
	}
	
	.filters__list {
		@media (min-width: @mq-l) {
			display: flex;
		}
	}
	
	.filter {
		@media (min-width: @mq-l) {
			margin: 0 1rem 0 0;
		}
	}
	
}


/*------------------------------------------------------------------------------
	/Grid
------------------------------------------------------------------------------*/
.blog-listings {
	margin: 1.5rem 0 0;
	@media (min-width: @mq-m) {
		margin-top: 3.5rem;
	}
	@media (min-width: @mq-l) {
		margin-top: 0;
	}
	+ .pagination-container .pagination {
		border-top: 1px solid @color-divider;
	}
}
.blog-grid {
	margin: 0;
	
	.c-card {
		margin-bottom: 2.75rem;
	}
	
	@media (min-width: @mq-m) {
		display: flex;
		flex-wrap: wrap;
		margin-left: -2.75rem;
		margin-bottom: -2.75rem;
		.c-card {
			width: 50%;
			padding-left: 2.75rem;
		}
	}
	
	@media (min-width: @mq-xl) {
		margin-left: -3rem;
		margin-bottom: -4.5rem;
		.c-card {
			width: (100% / 3);
			padding-left: 3rem;
			margin-bottom: 4.5rem;
		}
	}
}
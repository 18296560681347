/*------------------------------------------------------------------------------
	/Hero
------------------------------------------------------------------------------*/
.sizes-hero {
	position: relative;
	overflow: hidden;
	padding: 4rem 0 0;
	margin: -1px 0 0;
	background: @color-primary;
	color: #fff;
	@media (min-width: @mq-xl) {
		padding: 7.125rem 0 0;
		background-color: #fff;
		color: @color-primary;
	}
	@media (max-width: (@mq-xl - 0.0625em)) {
		.switch {
			background: #fff;
			border-color: #fff;
			&:after {
				background: @color-primary;
			}
		}
	}
}
.sizes-hero__layout {
	margin: 0;
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 2;
	@media (min-width: @mq-xl) {
		align-items: center;
	}
}
.sizes-hero__header {
	margin: 0 0 0.875rem;
	a {
		color: currentColor;
	}
}
.sizes-hero__options {
	margin: 0 0 2.5rem;
	@media (min-width: @mq-xl) {
		margin: 0 0 3.5rem;
	}
}
.sizes-hero__bg {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0.32;
	z-index: 0;
	display: none;
	@media (min-width: @mq-xl) {
		display: block;
	}
}



/*------------------------------------------------------------------------------
	/Subnav
------------------------------------------------------------------------------*/
.sizes-nav {
	position: relative;
	z-index: 2;
	display: flex;
	align-items: center;
	list-style: none;
	margin: 0 -1.5rem;
	padding: 0 1.5rem;
	overflow-x: auto;
	width: 100vw;
	scroll-snap-type: x mandatory;
	@media (min-width: @mq-l) {
		margin: 0 -3.5rem;
		padding-left: 3.5rem;
	}
	@media (min-width: @mq-xl) {
		width: 100%;
		margin: 0 0 4px;
		padding: 0;
	}
	&:after {
		content: "";
		min-width: 0.5rem;
		height: 1px;
		display: block;
	}
}
.sizes-nav__item {
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
	min-width: 10rem;
	min-height: 9.1875rem;
	margin: 0 0.25rem;
	padding: 0 0 0.5rem;
	scroll-behavior: smooth;
	scroll-snap-align: center;
	@media (min-width: @mq-xl) {
		min-width: 0;
		margin: 0.25rem;
		padding: 0;
	}
	@media (min-width: @mq-xxl) {
		min-width: 10.5rem;
	}
}
.sizes-nav__link {
	flex: 1;
	width: 100%;
	height: 100%;
	background: #fff;
	text-align: center;
	padding: 1rem 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 2px solid transparent;
	border-radius: @border-radius;
	.icon {
		width: 3.5rem;
		height: 3.5rem;
		margin: 0 0 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0;
		color: transparent;
	}
	.title {
		.weight-medium;
	}
	.dimensions {
		font-size: 0.76em;
	}
	.dimensions--imperial {
		
	}
	&:after {
		content: "";
		background: #fff;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 0.5rem;
		opacity: 0;
	}
	&:hover,
	&:focus {
		outline: 0;
		@media (min-width: @mq-xxl) {
			border-color: currentColor;
		}
	}
	&.is-active {
		border-color: @color-primary;
		@media (max-width: (@mq-xl - 0.0625em)) {
			border-color: #fff;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			&:after {
				opacity: 1;
			}
		}
	}
}


  /*----------------------------------------------------------------------------
  / Switch toggle checkbox
	---------------------------------------------------------------------------- */
	
	.sizes-hero__options {
		display: flex;
		align-items: center;
		.switch-ui {
			margin: 0 0 0 0.5rem;
		}
	}

  .switch-ui {
    display: inline-flex;
    align-items: center;
    line-height: 1;
    margin: 0;
    cursor: pointer;
  }

  .switch {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    background: @color-primary;
    width: 32px;
    height: 18px;
    border-radius: 1rem;
    border: 3px solid @color-primary;
    cursor: pointer;
    outline: 0;
		transition: .2s ease-in-out;

    + .switch-ui__label--a {
			margin-left: 0.5rem;
    }

    &:after {
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      width: 10px;
      height: 10px;
      background: #fff;
      border-radius: 50%;
      transition: .2s ease-in-out;
    }

    &[type="checkbox"],
    &[type="radio"] {
      &:hover,
      &:focus {
        outline: 0;
        // border-color: @color-body;
      }

      &:checked {

        &:after {
          left: calc(100% - 13px);
        }

        + .switch-ui__label--a {
          color: currentColor;
        }
      }
    }
	}
	
	.switch-ui__label {
		.weight-medium;
	}

  .switch-ui--two-labels {
		.switch-ui__label {
			opacity: 0.5;
			transition: opacity .15s;
		}
    .switch-ui__label--a {
      order: 1;
			margin: 0;
			opacity: 1;
    }

    .switch-ui__label--b {
      order: 3;
    }

    .switch {
      order: 2;
      margin: 0 6px;

      &[type="checkbox"],
      &[type="radio"] {
        &:checked {
          ~ .switch-ui__label--a {
            opacity: 0.5;
          }

          ~ .switch-ui__label--b {
            opacity: 1;
          }
        }
      }
    }
  }



/*------------------------------------------------------------------------------
	/Content
------------------------------------------------------------------------------*/
.sizes-content {
	padding: 2.25rem 0 4.75rem;
	@media (min-width: @mq-m) {
		padding: 4.5rem 0 9.5rem;
	}
}
.sizes-content__layout {
	
}
.sizes-content__media {
	position: relative;
	display: flex;
	flex-direction: column;
}
.sizes-content__image {
	height: 100%;
}
.sizes-content__download {
	margin: 1rem 0 2rem;
}


/*------------------------------------------------------------------------------
	/Detail
------------------------------------------------------------------------------*/
.template--sizes-detail {
	.related__layout {
		@media (min-width: @mq-xl) {
			padding-top: 5rem;
		}
	}
}
.sizes-detail {
	padding: 2.25rem 0 4rem;
	@media (min-width: @mq-l) {
		padding: 5.5rem 0 6.5rem;
	}
}
.sizes-detail__content {
	.loading {
		~ * {
			opacity: 0;
		}
	}
}
.sizes-detail__graphic {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 3rem 0 0;
	@media (min-width: @mq-l) {
		justify-content: flex-start;
	}
}